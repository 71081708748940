  import React, { FC, useContext, useState, useEffect } from "react";
  import { observer } from "mobx-react-lite";
  import {
    View,
    StyleSheet,
    Text,
    Image,
    TouchableOpacity,
    useColorScheme,
    Modal,
    Share
  } from "react-native";
  import { StackScreenProps } from "@react-navigation/stack";
  import { ActivityIndicator } from "react-native";
  import themeContext from "../theme/themeContext";
  import { useWindowDimensions } from "react-native";
  import { GameEngine } from "react-native-game-engine";
  import { GamePlayerViewScreen } from "./GamePlayerViewScreen";
  import { useHeaderHeight } from "@react-navigation/elements";
  import { Card } from "@rneui/base";
  import { useNavigation } from "@react-navigation/native";
  import { isMobile } from "react-device-detect";
  import { Screen } from "../components";
  import theme from "../theme/theme"
  import * as Localization from "expo-localization";
  import i18n from "../i18n/i18n";
  import { useTheme } from "../constant/TheamContext";

  // REMOVE ME! ⬇️ This TS ignore will not be necessary after you've added the correct navigator param type
  // @ts-ignore

  export const GameScreen: FC<StackScreenProps<AppStackScreenProps, "Games">> = observer(
    function GameScreen({ route }) {
      const { themeColor } = useTheme();
      
      const schema = themeColor === "dark" ? theme.dark : theme.light;
      
      const styles = useStyles();
      const { Url, gameSrc, gamePlay, title, gameName, gameplay } = route.params;
      const navigation = useNavigation();
      const prices = Math.floor(Math.random() * 5) * 50 + 100;

      const [isPopoverVisible, setIsPopoverVisible] = useState(false);

      const handleHowToPlay = () => {
        setIsPopoverVisible(true);
      };
      const hidePopover = () => {
        setIsPopoverVisible(false);
      };
      // Get system language
      const systemLanguage = Localization.getLocales()[0].languageCode;
      console.log(systemLanguage);

      i18n.locale = systemLanguage;

      const t = (key: TxKeyPath) => i18n.t(key);

      // Generate random players
      const players = Math.floor(Math.random() * 51) + 50;


      const handleShareButtonPress = async () => {
        try {
          const shareOptions = {
            message: 'Check out this awesome game!',
            // You can also include other properties like URL or title if needed
          };
    
          const result = await Share.share(shareOptions);
    
          if (result.action === Share.sharedAction) {
            if (result.activityType) {
              // Shared with activity type
              console.log(`Shared with activity type: ${result.activityType}`);
            } else {
              // Shared
              console.log('Shared successfully');
            }
          } else if (result.action === Share.dismissedAction) {
            // Dismissed
            console.log('Share dismissed');
          }
        } catch (error) {
          console.error('Error sharing:', error.message);
        }
      };
    

      return (
        <Screen
          style={{
            backgroundColor: schema.backgroundSecondary,
          }}
        >
          {!isMobile ? (
            <View>
              <Card
                containerStyle={{
                  margin: 0,
                  padding: 0,
                  backgroundColor: schema.backgroundSecondary,
                  border: "0px solid black",
                }}
              >
                <Image
                  source={gameSrc}
                  style={{ width: "100%", height: 100, opacity: 0.8, resizeMode: "center" }}
                />
                <Card
                  containerStyle={{
                    borderRadius: 20,
                    marginBottom: 35,
                  }}
                >
                  <View style={{ flexDirection: "row", marginBottom: 10 }}>
                    <Image
                      source={gameSrc}
                      style={{ width: 90, height: 90, borderRadius: 10 }}
                    />
                    <Text
                      style={{
                        marginLeft: 20,
                        width: "100%",
                        fontFamily: "PoppinsMedium",
                        fontSize: 20,
                      }}
                    >
                      {gameName}
                    </Text>
                  </View>

                  <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                    <View style={{ alignItems: "center" }}>
                      <Text style={{ fontFamily: "poppins", fontWeight: "900", fontSize: 20 }}>
                        {t("gameScreen.prizePool")}
                      </Text>
                      <Text>{prices}$</Text>
                    </View>
                    <View style={{ alignItems: "center" }}>
                      <Text style={{ fontFamily: "poppins", fontWeight: "900", fontSize: 20 }}>
                        {t("gameScreen.players")}
                      </Text>
                      <Text>{players}</Text>
                    </View>
                  </View>

                  <View style={{ marginTop: 20 }}>
                    <TouchableOpacity
                      onPress={() => navigation.navigate(gameplay, { Url, gameName })}
                      style={{ backgroundColor: "#5A96E3", borderRadius: 10, alignItems: "center" }}
                    >
                      <Text
                        style={{
                          color: "white",
                          marginTop: 10,
                          marginBottom: 10,
                          fontSize: 20,
                          fontFamily: "poppins",
                          fontWeight: "900",
                        }}
                      >
                        {t("gameScreen.playNow")}
                      </Text>
                    </TouchableOpacity>
                  </View>
                </Card>
              </Card>
            </View>
          ) : (
            <View
              style={{
                backgroundColor: schema.backgroundSecondary,
              }}
            >
              <Card
                containerStyle={{
                  margin: 0,
                  padding: 0,
                  backgroundColor: schema.backgroundSecondary,
                  border: "0px solid black",
                }}
              >
                <Image
                  source={gameSrc}
                  style={{ width: "100%", height: 250, opacity: 0.8, resizeMode: "stretch" }}
                />
                <Card
                  containerStyle={{
                    borderRadius: 20,
                    marginBottom: 35,
                  }}
                >
                  <View style={{ flexDirection: "row", marginBottom: 10 }}>
                    <Image
                      source={gameSrc}
                      style={{ width: 90, height: 90, borderRadius: 10 }}
                    />
                    <Text
                      style={{
                        marginLeft: 20,
                        width: "100%",
                        fontFamily: "PoppinsMedium",
                        fontSize: 20,
                      }}
                    >
                      {gameName}
                    </Text>

                    <TouchableOpacity onPress={handleHowToPlay}>
                      <View style={{ borderWidth: 1, borderRadius: 20, width: 25, height: 25, alignItems: 'center', justifyContent: 'center' }} >
                        <Image source={require('../../assets/icons/HowToPlay.png')} style={{ width: 15, height: 15 }} />
                        {/* <Text>How To play</Text> */}
                      </View>
                    </TouchableOpacity>
                  </View>

                  {isPopoverVisible && (

                    <Modal animationType="none" transparent={true} visible={isPopoverVisible}>
                      <View style={{ flex: 1 }}>
                        <TouchableOpacity style={{ flex: 1 }} onPress={hidePopover} />
                        <View style={{ height: "58%", backgroundColor: "white", borderTopLeftRadius: 10, borderTopRightRadius: 10, padding: 10, }}>
                          <View style={{ alignItems: 'center', }}>
                            <Text style={{ fontWeight: '700', fontSize: 20, }}>How To play</Text>
                          </View>
                          <View style={{ margin: 20 }}>
                            <Card containerStyle={{ flexDirection: 'row' }}>
                              <View style={{ flexDirection: 'row', }}>

                                <Image source={require('../../assets/images/pvp.png')} style={{ width: 50, height: 50 }} />
                                <View style={{ marginLeft: 10, width: "70%" }}>
                                  <Text>Play Against real Player</Text>
                                  <Text>You Will Be Matched rendomly to another Player </Text>
                                </View>
                              </View>
                            </Card>
                            <Card containerStyle={{ flexDirection: 'row' }}>
                              <View style={{ flexDirection: 'row', }}>
                                <Image source={require('../../assets/images/scoreforwin.png')} style={{ width: 50, height: 50 }} />
                                <View style={{ marginLeft: 10, width: "70%" }}>
                                  <Text>Score More to Win</Text>
                                  <Text>Score More Then Opponent to Win the Match </Text>
                                </View>
                              </View>
                            </Card>
                            <Card containerStyle={{ flexDirection: 'row' }}>
                              <View style={{ flexDirection: 'row', }}>

                                <Image source={require('../../assets/images/windaily.png')} style={{ width: 50, height: 50 }} />
                                <View style={{ marginLeft: 10, width: "70%" }}>
                                  <Text>Win Daily</Text>
                                  <Text>Player With the Highest Score Will Win the Match </Text>
                                </View>
                              </View>
                            </Card>
                          </View>
                        </View>
                      </View>
                    </Modal>
                  )}

                  <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                    <View style={{ alignItems: "center" }}>
                      <Text style={{ fontFamily: "poppins", fontWeight: "900", fontSize: 20 }}>
                        {t("gameScreen.prizePool")}
                      </Text>
                      <Text>{prices}$</Text>
                    </View>
                    <View style={{ alignItems: "center" }}>
                      <Text style={{ fontFamily: "poppins", fontWeight: "900", fontSize: 20 }}>
                        {t("gameScreen.players")}
                      </Text>
                      <Text>{players}</Text>
                    </View>
                  </View>

                  <View style={{ marginTop: 20 }}>
                    <TouchableOpacity
                      onPress={() => navigation.navigate(gameplay, { Url, gameName })}
                      style={{ backgroundColor: "#5A96E3", borderRadius: 10, alignItems: "center" }}
                    >
                      <Text
                        style={{
                          color: "white",
                          marginTop: 10,
                          marginBottom: 10,
                          fontSize: 20,
                          fontFamily: "poppins",
                          fontWeight: "900",
                        }}
                      >
                        {t("gameScreen.playNow")}
                      </Text>
                    </TouchableOpacity>
                  </View>
                </Card>
              </Card>
              <Card containerStyle={{backgroundColor:'#5A96E3', border:"none", borderRadius:10, }}>
                <View style={{flexDirection:'row', alignItems:'center', }}>
                  <View style={{width:"70%"}}>
                    <Text style={{color:"white", fontWeight:'900', fontSize:16, marginBottom:2}}>
                      The More, The Merrier!
                    </Text>
                    <Text style={{color:"white"}}>
                      Sgare The Excitement With Your Friends
                    </Text>
                  </View>
                  <TouchableOpacity       onPress={handleShareButtonPress}
                  style={{width:"30%",backgroundColor:'white', padding:10, alignItems:'center',  borderRadius:20, }}>
                    <Text style={{color:'#5A96E3', }}>Share Game</Text>
                  </TouchableOpacity>
                </View>
              </Card>
            </View>
          )}
        </Screen>
      );
    }
  );

  function useStyles() {
    const { width, height } = useWindowDimensions();

    return StyleSheet.create({});
  }
