import * as React from "react"
import {
    StyleSheet,
    TextStyle,
    View,
    StyleProp,
    ViewStyle,
    Image,
    TouchableOpacity,
} from "react-native"
import { observer } from "mobx-react-lite"
import { useNavigation } from "@react-navigation/native"
import { AppNavigator } from "../navigators"
import { Button, Text } from "react-native-paper"

export interface CategoryMenuButttonProps {
  /** * An optional style
override useful for padding & margin. */ style?: StyleProp<ViewStyle>
    textData
    imgSrc
    bgChange
    screen
    

} /** * Describe your component here */
export const CategoryMenuButtton = observer(function CategoryMenuButtton(
    props: CategoryMenuButttonProps,
) {
    const { style, textData, imgSrc, bgChange, screen } = props
    const navigation = useNavigation()

    const handleNavigate = (screen: any, textData) => {
        console.log(screen, textData)

        //   navigation.navigate('ActionScreen', { name: textData });
    }
    return (
        <>
        <View style={{alignItems:'center', justifyContent:"center"}}>
        <TouchableOpacity
                onPress={() => {navigation.navigate(screen, { textData, bgChange})}
                }
                style={[styles.button,  { backgroundColor: bgChange, width:100}]}
            >
                <Image
                    source={imgSrc}
                    style={styles.buttonIcon}
                    resizeMode="contain"
                />
                <Text style={styles.buttonText}>{textData}</Text>
            </TouchableOpacity>
        </View>

        </>
    )
})

const styles = StyleSheet.create({
    container: {
        margin: 4,
    },
    button: {
        alignItems:"center",
        borderRadius: 10,
        padding: 5,
        elevation: 5,
        shadowWidth: 5,
        marginRight:20

    },
    buttonContent: {
        flexDirection: "row",
    },
    buttonText: {
        color: "#fff",
        fontSize: 12,
        marginTop:5,
        fontWeight: "bold",
        justifyContent: "center",
    },
    buttonIcon: {
        width: 20,
        height: 20,
    },
})
