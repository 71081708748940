import React, { FC, useContext, useState } from "react";
import { observer } from "mobx-react-lite";
import { View, StyleSheet, Text, Dimensions, ScrollView, useColorScheme } from "react-native";
import { StackScreenProps } from "@react-navigation/stack";
import { AppStackScreenProps } from "../navigators";
import themeContext from "../theme/themeContext";
import { useWindowDimensions } from "react-native";
import { SissionScreenGames } from "../components/SissionScreenGames";
import Draggable from "react-draggable";
import { Appearance } from "react-native-appearance";
import theme from "../theme/theme";
import { isMobile } from "react-device-detect";
import { Screen } from "../components";
import { useTheme } from "../constant/TheamContext";
import AutoGrid from "../components/Autogrid"
// REMOVE ME! ⬇️ This TS ignore will not be necessary after you've added the correct navigator param type
// @ts-ignore

export const SessionsScreen: FC<StackScreenProps<AppStackScreenProps, "Sessions">> = observer(function SessionsScreen() {
  const styles = useStyles();

  const { themeColor } = useTheme();

  const schema = themeColor === "dark" ? theme.dark : theme.light;

  const GameSession = [
    {
      id: 0,
      source: require("../../assets/images/Bird-Tumble.png"),
      PrizePool: "700",
      Players: Math.floor(Math.random() * (2500 - 100 + 1)) + 100,
      BackGround: schema.action,
      GameOverTime: 160,
    },
    {
      id: 1,
      source: require("../../assets/images/Candy-Absolute.png"),
      PrizePool: "700",
      Players: Math.floor(Math.random() * (2500 - 100 + 1)) + 100,
      BackGround: schema.adventure,
      GameOverTime: 150,
    },
    {
      id: 2,
      source: require("../../assets/images/Drag-Ball.png"),
      PrizePool: "700",
      Players: Math.floor(Math.random() * (2500 - 100 + 1)) + 100,
      BackGround: schema.memory,
      GameOverTime: 170,
    },
    {
      id: 3,
      source: require("../../assets/images/Dunk-Hoop.png"),
      PrizePool: "700",
      Players: Math.floor(Math.random() * (2500 - 100 + 1)) + 100,
      BackGround: schema.Shooting,
      GameOverTime: 190,
    },
    {
      id: 4,
      source: require("../../assets/images/Hole Hustle.png"),
      PrizePool: "700",
      Players: Math.floor(Math.random() * (2500 - 100 + 1)) + 100,
      BackGround: schema.puzzle,
      GameOverTime: 200,
    },
    {
      id: 5,
      source: require("../../assets/images/jackpot-Spin-&-Win.png"),
      PrizePool: "700",
      Players: Math.floor(Math.random() * (2500 - 100 + 1)) + 100,
      BackGround: schema.casino,
      GameOverTime: 210,
    },
    {
      id: 6,
      source: require("../../assets/images/Jockey-Dash.png"),
      PrizePool: "700",
      Players: Math.floor(Math.random() * (2500 - 100 + 1)) + 100,
      BackGround: schema.card,
      GameOverTime: 120,
    },
    {
      id: 7,
      source: require("../../assets/images/Jockey-Dash.png"),
      PrizePool: "700",
      Players: Math.floor(Math.random() * (2500 - 100 + 1)) + 100,
      BackGround: schema.action,
      GameOverTime: 120,
    },

  ];

  return (
    <Screen preset="scroll" style={{ backgroundColor: schema.backgroundSecondary }}>
      {!isMobile ? (
        <View style={{ margin: "auto", alignItems: "center", justifyContent: 'center' }}>
          <View style={[styles.SessionGameDisplayDataWidthResposive]}>
            <AutoGrid itemWidth={250}>
              {GameSession.map((GameSession, i) => (
                <SissionScreenGames
                  key={`${GameSession.id}-${i++}`}
                  source={GameSession.source}
                  PrizePool={GameSession.PrizePool}
                  Players={GameSession.Players}
                  BackGround={GameSession.BackGround}
                  GameOverTime={GameSession.GameOverTime}
                  {...GameSession}
                  {...SissionScreenGames}
                />
              ))}
            </AutoGrid>

          </View>

          <View style={{ height: 100 }}></View>
        </View>
      ) : (
        <>
          <View style={{ margin: "auto", alignItems: "center", justifyContent: 'center' }}>
            <View style={[styles.SessionGameDisplayDataWidthResposive]}>
              <AutoGrid itemWidth={160}>
                {GameSession.map((GameSession, i) => (
                  <SissionScreenGames
                    key={`${GameSession.id}-${i++}`}
                    source={GameSession.source}
                    PrizePool={GameSession.PrizePool}
                    Players={GameSession.Players}
                    BackGround={GameSession.BackGround}
                    GameOverTime={GameSession.GameOverTime}
                    {...GameSession}
                    {...SissionScreenGames}
                  />
                ))}
              </AutoGrid>

            </View>

            <View style={{ height: 100 }}></View>
          </View>
        </>

      )}


    </Screen >
  );
});

function useStyles() {
  const { width, height } = useWindowDimensions();
  return StyleSheet.create({
    SessionGameDisplayDataWidthResposive: {
      width: width,
      alignItems: "center",
      justifyContent: 'center',
    },
  });
}
