import React, { useState } from "react"
import { createStackNavigator } from "@react-navigation/stack"
import { HomeScreen } from "../screens"
import { NavigatorScreenParams } from "@react-navigation/native"  
import { Image, Text, View, Dimensions, TouchableOpacity, SafeAreaView } from "react-native"
import { SessionsScreen } from "../screens/SessionsScreen"
import { TrophiesScreen } from "../screens/TrophiesScreen"
import { GamePlayerViewScreen } from "../screens/GamePlayerViewScreen";
import { ActionsScreen } from "../screens/ActionsScreen"
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs"
import { Appearance } from "react-native-appearance"
import theme from "../theme/theme"
import { LeaderBoardScreen } from "../screens/LeaderBoardScreen"
import { GameScreen } from "../screens/GameScreen"
import { useNavigation } from "@react-navigation/native";
import { UserProfileScreen } from "../screens"
import { UserRegistrationScreen } from "../screens/UserRegistrationScreen"
import { ThemeProvider } from "../constant/TheamContext"
import { useTheme } from "../constant/TheamContext"
import { EditScreen } from "../screens"
export type StackSceenNevigationNavigatorParamList = {
  Action: undefined
  Board: undefined
  Card: undefined
  Casino: undefined
  Memory: undefined
  Pizzle: undefined
  Strategy: undefined
  Quiz: undefined
  Shooting: undefined
  Sports: undefined
  Survival: undefined
  Number: undefined
  HomeScreen: undefined
  GameScreen: undefined
  CandyabsoluteScreen: undefined
  DragnballScreen: undefined
  DunkDash: undefined
  FirstFury: undefined
  HoleHustleScreen: undefined
  GamePlayerViewScreen: undefined
  JockeyDashScreen: undefined
  SpaceWarriorScreen: undefined
  FeedThePubScreen: undefined
  Adventure: undefined
  user: undefined
  UserLogin: undefined
  Edit:undefined
}



export const StackSceenNevigationNavigator = () => {

  // const colorScheme = Appearance.getColorScheme()
  // const schema = colorScheme === "dark" ? theme.dark : theme.light
  
  const { themeColor } = useTheme();
      
 const schema = themeColor === "dark" ? theme.dark : theme.light;
      
  const Tab = createBottomTabNavigator<NavigatorScreenParams>()
  const windowWidth = Dimensions.get('window').width;
  const Stack = createStackNavigator<StackSceenNevigationNavigatorParamList>()
  const navigation = useNavigation();

  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  // const navigation = useNavigation();

  // Function to handle user login
  const handleLogin = () => {
    // Perform login logic...
    setIsUserLoggedIn(false);
  };

  // Function to handle user logout
  const handleLogout = () => {
    // Perform logout logic...
    setIsUserLoggedIn(false);
  };

  return (

    <Stack.Navigator
      screenOptions={{
        cardStyle: { backgroundColor: "transparent" },
        headerShown: true,
      }}
      initialRouteName="HomeScreen"
    
    >

      <Stack.Screen
        name="UserLogin"
        component={UserRegistrationScreen}

        options={() => ({
          headerTitleAlign: 'left',
          headerShown: false,
          headerStyle: {
            backgroundColor: schema.backgroundPrimary,
          },
          headerTitleStyle: {
            fontSize: 20,
            fontWeight: 'bold',
            color: schema.color,
            alignSelf: "center",
          },
          headerTintColor: schema.color,
        })}
      />
      <Stack.Screen
       name="Edit"
       component={EditScreen}
       options={() => ({
        title: "Edit Profile",
        headerTitleAlign: 'center',
        headerStyle: {
          backgroundColor: schema.backgroundPrimary,
        },
        headerTitleStyle: {
          fontSize: 20,
          fontWeight: 'bold',
          color: schema.color,
          alignSelf: "center",
        },
        headerTintColor: schema.color,
      })}
      />

      <Stack.Screen
        name="user"
        component={UserProfileScreen}
        options={() => ({
          title: "Profile",
          headerTitleAlign: 'center',
          headerStyle: {
            backgroundColor: schema.backgroundPrimary,
          },
          headerTitleStyle: {
            fontSize: 20,
            fontWeight: 'bold',
            color: schema.color,
            alignSelf: "center",
          },
          headerTintColor: schema.color,
        })}
      />
      <Stack.Screen
        name="Action"
        component={ActionsScreen}
        options={({ route }) => ({
          title: route?.params?.textData || route?.params?.textData || route?.params?.bgChange || "",
          headerTitleAlign: 'center',
          headerStyle: {
            backgroundColor: schema.backgroundPrimary,
          },
          headerTitleStyle: {
            fontSize: 20,
            fontWeight: 'bold',
            color: schema.color,
            alignSelf: "center",
          },
          // headerLeft: (props) => (
          //   <TouchableOpacity onPress={() => navigation.goBack()}>
          //     <Text style={{color: schema.color, margin:10, }}>Back</Text>
          //   </TouchableOpacity>
          // ),
          headerTintColor: schema.color,
        })}
      />

      {/* Game Screen */}
      <Stack.Screen
        name="GameScreen"
        component={GameScreen}
        options={({ route }) => ({
          title: route?.params?.gameName || route?.params?.gameSrc || "",
          headerTitleAlign: 'center',
          headerStyle: {
            backgroundColor: schema.backgroundPrimary,
          },
          headerTitleStyle: {
            fontSize: 20,
            fontWeight: 'bold',
            color: schema.color,
            alignSelf: "center",
          },
          
          
          headerTintColor: schema.color,
        })}
      />

      <Stack.Screen
        name="GamePlayerViewScreen"
        component={GamePlayerViewScreen}
        options={({ route }) => ({
          title: route?.params?.gameName || route?.params?.gameSrc || route?.params.gameplay || " ",
          headerTitleAlign: 'center',
          headerStyle: {
            backgroundColor: schema.backgroundPrimary,
          },
          headerTitleStyle: {
            fontSize: 20,
            fontWeight: 'bold',
            color: schema.color,
            alignSelf: "center",
          },
          headerLeft: (props) => (
            <TouchableOpacity onPress={() => navigation.navigate("HomeScreen")}>
              <Text style={{color: schema.color, margin:10, }}>Back</Text>
            </TouchableOpacity>
          ),
          headerTintColor: schema.color,
        })}
      />
      {/* Game Screen End  */}

      <Stack.Screen
        name="HomeScreen"
        options={{
          headerShown: true,
          headerStyle: {
            width: windowWidth,
            backgroundColor: schema.backgroundPrimary,
            height: 95,
            padding: 0,
            margin: 0,
            shadowColor: schema.shadow,
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: schema.theme === 'dark' ? 0 : 0.8,
            shadowRadius: 2,
            elevation: 1,
          },
          headerTitle: () =>
            isUserLoggedIn ? (
              <SafeAreaView>
                {/* Logged-in header */}
                <View
                  style={{
                    flexDirection: "row",
                    width: windowWidth - 20,
                    justifyContent: "space-between",
                  }}
                >
                  <View style={{ width: 300 }}>
                    <Image
                      source={schema.logo}
                      style={{
                        width: 240,
                        height: 72,
                        alignSelf: "flex-start",
                        marginTop: 5,
                        resizeMode: "cover",
                      }}
                    />
                  </View>

                  <TouchableOpacity
                    onPress={() => navigation.navigate("user")}
                    style={{ alignItems: "center", marginRight: 10 }}
                  >
                    <Image
                      source={schema.user}
                      style={{ width: 50, height: 52, alignSelf: "flex-end" }}
                    />
                    <Text style={{ color: schema.color, alignSelf: "center" }}>
                      Stinger_20
                    </Text>
                  </TouchableOpacity>
                </View>
              </SafeAreaView>
            ) : (
              // Logged-out header
              <SafeAreaView>
                {/* Logged-in header */}
                <View
                  style={{
                    flexDirection: "row",
                    width: windowWidth - 20,
                    justifyContent: "space-between",
                  }}
                >
                  <View style={{ width: 300 }}>
                    <Image
                      source={schema.logo}
                      style={{
                        width: 240,
                        height: 72,
                        alignSelf: "flex-start",
                        marginTop: 5,
                        resizeMode: "cover",
                      }}
                    />
                  </View>

                  <TouchableOpacity onPress={() => navigation.navigate("UserLogin")} style={{alignItems: "center", marginTop:10, marginRight: 10  }}>
                    <Image
                      source={schema.profile}
                      style={{ width: 50, height: 52, alignSelf: "flex-end" }}
                    />
                    
                  </TouchableOpacity>
                </View>
              </SafeAreaView>

            ),
        }}
        component={() => (
          <Tab.Navigator
            screenOptions={{
              headerTitleAlign: "center",
              headerStyle: {
                backgroundColor: schema.backgroundPrimary,
                height: 80,
              },
              headerShown: false,
              tabBarShowLabel: true,
              tabBarLabelPosition: "below-icon",
              tabBarLabelStyle: {
                fontSize: 8,
                fontWeight: "bold",
                margin: 5,
                color: schema.color,
              },
              tabBarIconStyle: {
                width: 25,
                height: 25,
              },
              tabBarStyle: {
                borderRadius: 10,
                borderWidth: 0,
                backgroundColor: schema.backgroundPrimary,
                margin: 10,
                height: 60,
                position: "absolute",
                bottom: 10,
              },
            }}
            initialRouteName="Home"
          >
            <Tab.Screen
              name="LeaderBoard"
              component={LeaderBoardScreen}
              options={{
                headerShown: false,
                headerTitle: () => (
                  <Image
                    source={schema.logo}
                    style={{ width: 240, height: 72, alignSelf: "center" }}
                  />
                ),
                tabBarIcon: ({ focused, size }) => {
                  if (focused) {
                    size = size * 2
                  } else { size = size * 1.5 }
                  return (
                    <Image
                      style={{
                        width: size,
                        height: size,
                      }}
                      source={focused ? schema.leaderboardFocus : schema.leaderboard}
                    />
                  )
                },
              }}
            />

            <Tab.Screen
              name="Activity"
              component={SessionsScreen}
              options={{
                headerTitle: () => (
                  <View style={{ width: 1100, flexDirection: 'row', flex: 1, }}>
                    <View>
                      <Image
                        source={schema.logo}
                        style={{ width: 240, height: 72, }}
                      />
                    </View>
                    <Image
                      source={schema.logo}
                      style={{ width: 240, height: 72, }}
                    />
                  </View>
                ),
                tabBarIcon: ({ focused, size }) => {
                  if (focused) {
                    size = size * 2
                  } else { size = size * 1.5 }
                  return (
                    <Image
                      style={{
                        width: size,
                        height: size,
                      }}
                      source={focused ? schema.activityFocus : schema.activity}
                    />
                  )
                },
              }}
            />

            <Tab.Screen
              name="Home"
              component={HomeScreen}
              options={{
                headerTitle: () => (
                  <Image
                    source={schema.logo}
                    style={{ width: 240, height: 72, alignSelf: "center" }}
                  />
                ),
                tabBarIcon: ({ focused, size }) => {
                  if (focused) {
                    size = size * 2
                  } else { size = size * 1.5 }
                  return (
                    <Image
                      style={{
                        width: size,
                        height: size,
                      }}
                      source={focused ? schema.homeFocus : schema.home}
                    />
                  )
                },
                title: "Home",
              }}
            />

            <Tab.Screen
              name="Sessions"
              component={SessionsScreen}
              options={{
                headerTitle: () => (
                  <Image
                    source={schema.logo}
                    style={{ width: 240, height: 72, alignSelf: "center" }}
                  />
                ),
                tabBarIcon: ({ focused, size }) => {
                  if (focused) {
                    size = size * 2
                  } else { size = size * 1.5 }
                  return (
                    <Image
                      style={{
                        width: size,
                        height: size,
                      }}
                      source={focused ? schema.sessionsFocus : schema.sessions}
                    />
                  )
                },
                title: "Sessions",
              }}
            />

            <Tab.Screen
              name="Trophies"
              component={TrophiesScreen}
              options={{
                headerTitle: () => (
                  <Image
                    source={schema.logo}
                    style={{ width: 240, height: 72, alignSelf: "center" }}
                  />
                ),
                tabBarIcon: ({ focused, size }) => {
                  if (focused) {
                    size = size * 2
                  } else { size = size * 1.5 }
                  return (
                    <Image
                      style={{
                        width: size,
                        height: size,
                      }}
                      source={focused ? schema.trophiesFocus : schema.trophies}
                    />
                  )
                },
                title: "Trophies",
              }}
            />
          </Tab.Navigator>
        )}
      />
    </Stack.Navigator>
  )
}
