import * as Localization from "expo-localization";
import i18n from "i18n-js";

import en from "./en";
import th from "./th"; 
import hi from "./hi";

// Import other language files as needed

i18n.fallbacks = true;
i18n.translations = { en,th,hi };
i18n.locale = Localization.locale;

export default i18n;
