import React, { FC, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  View,
  ScrollView,
  StyleSheet,
  useColorScheme,
  PanResponder,
  useWindowDimensions,
  Image

} from "react-native";
import { StackScreenProps } from "@react-navigation/stack";
import { AppStackScreenProps } from "../navigators";
import { Card, Text, TopGameCard, QuizGame, CategoryMenuButtton } from "../components";
import { responsiveHeight, responsiveWidth } from "react-native-responsive-dimensions";
import FeaturedCard from "../components/FeaturedCard";
import { fc, qc, bd, cd } from "../constant/imagePath";
import { isMobile } from "react-device-detect";
import theme from "../theme/theme";
import Draggable from "react-draggable";
import Carousel from "react-native-snap-carousel";
import { parallaxLayout } from "./parallax";
import { useTheme } from "../constant/TheamContext";
import AutoGrid from "../components/Autogrid";
import { DisplayCards } from "../components/DisplayCards";
import { DisplayCardsSecound } from "../components/DisplayCardsSecound";

// REMOVE ME! ⬇️ This TS ignore will not be necessary after you've added the correct navigator param type
// @ts-ignore

export const HomeScreen: FC<StackScreenProps<AppStackScreenProps, "Home">> = observer(
  function HomeScreen(props) {

    const { themeColor } = useTheme();
    const schema = themeColor === "dark" ? theme.dark : theme.light;

    const [scrollWidth, setScrollWidth] = useState(0);

    const handleDrag = (e, data, type) => {
      const maxScrollWidth = scrollWidth - width;
      if (data.x < -maxScrollWidth) {
        data.x = -maxScrollWidth;
      }
      if (data.x > 0) {
        data.x = 0;
      }
    };

    const handleLayout = (e) => {
      const maxScrollWidth = (ITEM_SPACING + ITEM_WIDTH) * MAX_ITEM - width * 0.9;
      setScrollWidth(maxScrollWidth);
    };

    const { width } = useWindowDimensions();
    const ITEM_WIDTH = 160; // Set the width of each child component
    const ITEM_SPACING = 20; // Calculate the spacing needed to spread children components equally
    const MAX_ITEM = 20; // Set the maximum number of children components to be displayed

    const styles = useStyles();
    const itemsPerIteration = 5;

    const featuredCard = fc(schema);
    const QuizCardsData = qc(schema);
    const buttonsData = bd(schema);
    const CardsData = cd(schema);
    const idsToSend = CardsData.map(CardData => CardData.id);

    const panResponder = PanResponder.create({
      onMoveShouldSetPanResponder: (event, gestureState) => gestureState.dy > 5,
      onPanResponderMove: (event, gestureState) => {
        if (gestureState.dy > 5) {
        }
      },
    });

    const renderLayout = () => {
      const layouts = [];
      const layoutsSecound = [];

      layouts.push(
        <View style={{}}>
          <View style={{ width: "25%", flexDirection: "row" }}>
            {CardsData.slice(0, 5).map((CardData, j) => (
              <View key={j}>
                <View style={{ flexDirection: "row" }}>
                  <DisplayCards
                    key={`${CardData.id}-${j}`}
                    id={CardData.id}
                    gameSrc={CardData.gameSrc}
                    background={CardData.background}
                    onGameClick={CardData.screen}
                    Url={CardData.Url}
                    gameplay={CardData.OnPlayerScreen}
                    {...CardData}
                  />
                  <View>
                    {j + 4 < CardsData.length && (
                      <DisplayCardsSecound
                        key={`${CardsData[j + 4].id}-${j + 4}`}
                        gameSrc={CardsData[j + 4].gameSrc}
                        background={CardsData[j + 4].background}
                        onGameClick={CardsData[j + 4].screen}
                        Url={CardsData[j + 4].Url}
                        gameplay={CardsData[j + 4].OnPlayerScreen}

                        gameName={CardsData[j + 4].gameName}
                        title={""}

                      />
                    )}
                    {j + 5 < CardsData.length && (
                      <DisplayCardsSecound
                        key={`${CardsData[j + 5].id}-${j + 5}`}
                        gameSrc={CardsData[j + 5].gameSrc}
                        background={CardsData[j + 5].background}
                        onGameClick={CardsData[j + 5].screen}
                        Url={CardsData[j + 5].Url}
                        gameplay={CardsData[j + 5].OnPlayerScreen}
                        gameName={CardsData[j + 5].gameName}
                        title={""}
                      />
                    )}
                  </View>
                </View>
                <View style={{ flexDirection: "row" }}>
                  {j + 6 < CardsData.length && (
                    <DisplayCardsSecound
                      key={`${CardsData[j + 6].id}-${j + 6}`}
                      gameSrc={CardsData[j + 6].gameSrc}
                      background={CardsData[j + 6].background}
                      onGameClick={CardsData[j + 6].screen}
                      Url={CardsData[j + 6].Url}
                      gameplay={CardsData[j + 6].OnPlayerScreen}
                      gameName={CardsData[j + 6].gameName}
                      title={""}
                    />
                  )}
                  {j + 7 < CardsData.length && (
                    <DisplayCardsSecound
                      key={`${CardsData[j + 7].id}-${j + 7}`}
                      gameSrc={CardsData[j + 7].gameSrc}
                      background={CardsData[j + 7].background}
                      onGameClick={CardsData[j + 7].screen}
                      Url={CardsData[j + 7].Url}
                      gameplay={CardsData[j + 7].OnPlayerScreen}
                      gameName={CardsData[j + 7].gameName}
                      title={""}
                    />
                  )}
                  {j + 8 < CardsData.length && (
                    <DisplayCardsSecound
                      key={`${CardsData[j + 8].id}-${j + 8}`}
                      gameSrc={CardsData[j + 8].gameSrc}
                      background={CardsData[j + 8].background}
                      onGameClick={CardsData[j + 8].screen}
                      Url={CardsData[j + 8].Url}
                      gameplay={CardsData[j + 8].OnPlayerScreen}
                      gameName={CardsData[j + 8].gameName}
                      title={""}
                    />
                  )}
                </View>
              </View>
            ))}
          </View>
        </View>
      );
      layouts.push(
        <View style={{}}>
          <View style={{ width: "25%", flexDirection: "row" }}>
            {CardsData.slice(5, 10).map((CardData, j) => (
              <View key={j}>
                <View style={{ flexDirection: "row" }}>

                  <View>
                    {j + 4 < CardsData.length && (
                      <DisplayCardsSecound
                        key={`${CardsData[j + 4].id}-${j + 4}`}
                        gameSrc={CardsData[j + 4].gameSrc}
                        background={CardsData[j + 4].background}
                        onGameClick={CardsData[j + 4].screen}
                        Url={CardsData[j + 4].Url}
                        gameplay={CardsData[j + 4].OnPlayerScreen}
                        gameName={CardsData[j + 4].gameName}
                        title={""}
                      />
                    )}
                    {j + 5 < CardsData.length && (
                      <DisplayCardsSecound
                        key={`${CardsData[j + 5].id}-${j + 5}`}
                        gameSrc={CardsData[j + 5].gameSrc}
                        background={CardsData[j + 5].background}
                        onGameClick={CardsData[j + 5].screen}
                        Url={CardsData[j + 5].Url}
                        gameplay={CardsData[j + 5].OnPlayerScreen}
                        gameName={CardsData[j + 5].gameName}
                        title={""}
                      />
                    )}
                  </View>
                  <DisplayCards
                    key={`${CardData.id}-${j}`}
                    id={CardData.id}
                    gameSrc={CardData.gameSrc}
                    background={CardData.background}
                    onGameClick={CardData.screen}
                    Url={CardData.Url}
                    gameplay={CardData.OnPlayerScreen}
                    {...CardData}
                  />
                </View>
                <View style={{ flexDirection: "row" }}>
                  {j + 6 < CardsData.length && (
                    <DisplayCardsSecound
                      key={`${CardsData[j + 6].id}-${j + 6}`}
                      gameSrc={CardsData[j + 6].gameSrc}
                      background={CardsData[j + 6].background}
                      onGameClick={CardsData[j + 6].screen}
                      Url={CardsData[j + 6].Url}
                      gameplay={CardsData[j + 6].OnPlayerScreen}
                      gameName={CardsData[j + 6].gameName}
                      title={""}
                    />
                  )}
                  {j + 7 < CardsData.length && (
                    <DisplayCardsSecound
                      key={`${CardsData[j + 7].id}-${j + 7}`}
                      gameSrc={CardsData[j + 7].gameSrc}
                      background={CardsData[j + 7].background}
                      onGameClick={CardsData[j + 7].screen}
                      Url={CardsData[j + 7].Url}
                      gameplay={CardsData[j + 7].OnPlayerScreen}
                      gameName={CardsData[j + 7].gameName}
                      title={""}
                    />
                  )}
                  {j + 8 < CardsData.length && (
                    <DisplayCardsSecound
                      key={`${CardsData[j + 8].id}-${j + 8}`}
                      gameSrc={CardsData[j + 8].gameSrc}
                      background={CardsData[j + 8].background}
                      onGameClick={CardsData[j + 8].screen}
                      Url={CardsData[j + 8].Url}
                      gameplay={CardsData[j + 8].OnPlayerScreen}
                      gameName={CardsData[j + 8].gameName}
                      title={""}
                    />
                  )}
                </View>
              </View>
            ))}
          </View>
        </View>
      );
      return layouts;
    };


    const renderMobile = () => {
      const layoutsSecound = [];

      layoutsSecound.push(

        <View style={{ width: "100%", marginTop: 20 }}>
          {CardsData.slice(0, 1).map((CardData, i) => (
            <View style={{ marginBottom: 30, alignItems: "center" }}>
              <View style={{ flexDirection: "row", }}>
                <DisplayCards
                  key={`${CardData.id}-${i++}`}
                  id={CardData.id}
                  gameSrc={CardData.gameSrc}
                  background={CardData.background}
                  onGameClick={CardData.screen}
                  Url={CardData.Url}
                  gameplay={CardData.OnPlayerScreen}
                  {...CardData}
                  {...TopGameCard}
                />
                <View>
                  {i + 4 < CardsData.length && (
                    <DisplayCardsSecound
                      key={`${CardsData[i + 4].id}-${i + 4}`}
                      gameSrc={CardsData[i + 4].gameSrc}
                      background={CardsData[i + 4].background}
                      onGameClick={CardsData[i + 4].screen}
                      Url={CardsData[i + 4].Url}
                      gameplay={CardsData[i + 4].OnPlayerScreen}
                      gameName={CardsData[i + 4].gameName}
                      title={""}
                    />
                  )}
                  {i + 5 < CardsData.length && (
                    <DisplayCardsSecound
                      key={`${CardsData[i + 5].id}-${i + 5}`}
                      gameSrc={CardsData[i + 5].gameSrc}
                      background={CardsData[i + 5].background}
                      onGameClick={CardsData[i + 5].screen}
                      Url={CardsData[i + 5].Url}
                      gameplay={CardsData[i + 5].OnPlayerScreen}
                      gameName={CardsData[i + 5].gameName}
                      title={""}
                    />
                  )}
                </View>
              </View>
              <View style={{ flexDirection: "row", }}>
                {i + 6 < CardsData.length && (
                  <DisplayCardsSecound
                    key={`${CardsData[i + 6].id}-${i + 6}`}
                    gameSrc={CardsData[i + 6].gameSrc}
                    background={CardsData[i + 6].background}
                    onGameClick={CardsData[i + 6].screen}
                    Url={CardsData[i + 6].Url}
                    gameplay={CardsData[i + 6].OnPlayerScreen}
                    gameName={CardsData[i + 6].gameName}
                    title={""}
                  />
                )}
                {i + 7 < CardsData.length && (
                  <DisplayCardsSecound
                    key={`${CardsData[i + 7].id}-${i + 7}`}
                    gameSrc={CardsData[i + 7].gameSrc}
                    background={CardsData[i + 7].background}
                    onGameClick={CardsData[i + 7].screen}
                    Url={CardsData[i + 7].Url}
                    gameplay={CardsData[i + 7].OnPlayerScreen}
                    gameName={CardsData[i + 7].gameName}
                    title={""}
                  />
                )}
                {i + 8 < CardsData.length && (
                  <DisplayCardsSecound
                    key={`${CardsData[i + 8].id}-${i + 8}`}
                    gameSrc={CardsData[i + 8].gameSrc}
                    background={CardsData[i + 8].background}
                    onGameClick={CardsData[i + 8].screen}
                    Url={CardsData[i + 8].Url}
                    gameplay={CardsData[i + 8].OnPlayerScreen}
                    gameName={CardsData[i + 8].gameName}
                    title={""}
                  />
                )}
              </View>

            </View>
          ))}


        </View>

      );
      layoutsSecound.push(
        <View style={{ width: "100%", marginTop: 20 }}>
          {CardsData.slice(2, 3).map((CardData, i) => (
            <View style={{ marginBottom: 30, alignItems: "center" }}>
              <View style={{ flexDirection: "row", }}>

                <View>
                  {i + 4 < CardsData.length && (
                    <DisplayCardsSecound
                      key={`${CardsData[i + 4].id}-${i + 4}`}
                      gameSrc={CardsData[i + 4].gameSrc}
                      background={CardsData[i + 4].background}
                      onGameClick={CardsData[i + 4].screen}
                      Url={CardsData[i + 4].Url}
                      gameplay={CardsData[i + 4].OnPlayerScreen}
                      gameName={CardsData[i + 4].gameName}
                      title={""}
                    />
                  )}
                  {i + 5 < CardsData.length && (
                    <DisplayCardsSecound
                      key={`${CardsData[i + 5].id}-${i + 5}`}
                      gameSrc={CardsData[i + 5].gameSrc}
                      background={CardsData[i + 5].background}
                      onGameClick={CardsData[i + 5].screen}
                      Url={CardsData[i + 5].Url}
                      gameplay={CardsData[i + 5].OnPlayerScreen}
                      gameName={CardsData[i + 5].gameName}
                      title={""}
                    />
                  )}
                </View>
                <DisplayCards
                  key={`${CardData.id}-${i++}`}
                  id={CardData.id}
                  gameSrc={CardData.gameSrc}
                  background={CardData.background}
                  onGameClick={CardData.screen}
                  Url={CardData.Url}
                  gameplay={CardData.OnPlayerScreen}
                  {...CardData}
                  {...TopGameCard}
                />
              </View>
              <View style={{ flexDirection: "row", }}>
                {i + 6 < CardsData.length && (
                  <DisplayCardsSecound
                    key={`${CardsData[i + 6].id}-${i + 6}`}
                    gameSrc={CardsData[i + 6].gameSrc}
                    background={CardsData[i + 6].background}
                    onGameClick={CardsData[i + 6].screen}
                    Url={CardsData[i + 6].Url}
                    gameplay={CardsData[i + 6].OnPlayerScreen}
                    gameName={CardsData[i + 6].gameName}
                    title={""}
                  />
                )}
                {i + 7 < CardsData.length && (
                  <DisplayCardsSecound
                    key={`${CardsData[i + 7].id}-${i + 7}`}
                    gameSrc={CardsData[i + 7].gameSrc}
                    background={CardsData[i + 7].background}
                    onGameClick={CardsData[i + 7].screen}
                    Url={CardsData[i + 7].Url}
                    gameplay={CardsData[i + 7].OnPlayerScreen}
                    gameName={CardsData[i + 7].gameName}
                    title={""}
                  />
                )}
                {i + 8 < CardsData.length && (
                  <DisplayCardsSecound
                    key={`${CardsData[i + 8].id}-${i + 8}`}
                    gameSrc={CardsData[i + 8].gameSrc}
                    background={CardsData[i + 8].background}
                    onGameClick={CardsData[i + 8].screen}
                    Url={CardsData[i + 8].Url}
                    gameplay={CardsData[i + 8].OnPlayerScreen}
                    gameName={CardsData[i + 8].gameName}
                    title={""}
                  />
                )}
              </View>

            </View>
          ))}

        </View>
      );

      return layoutsSecound;
    };
    return (
      <>
     
        <View style={{ backgroundColor: schema.backgroundSecondary, paddingTop:20, paddingLeft:20, paddingBottom:20 }}>
        <ScrollView
                  horizontal={true}
                  onLayout={handleLayout}
                  showsHorizontalScrollIndicator={false}
                  showsVerticalScrollIndicator={false}
                  alwaysBounceHorizontal={false}
                  style={styles.scrollView}
                >


          {!isMobile ? (
            <Draggable
              axis="x"
              onDrag={(e, data) => {
                handleDrag(e, data, "menu");
              }}
              bounds={{ left: 10, right: 0 }}
            >
              <View style={styles.buttonElements}>
                {buttonsData.map((buttonData, i) => (
                  <CategoryMenuButtton
                    style={{ width: ITEM_WIDTH, }}
                    key={`${buttonData.screen}-${i++}`}
                    imgSrc={buttonData.imgSrc}
                    bgChange={buttonData.bgChange}
                    screen={buttonData.screen}
                    {...buttonData}
                    {...CategoryMenuButtton}
                  />
                ))}
              </View>
            </Draggable>
          ) : (
            <View style={styles.buttonElements}>
              {buttonsData.map((buttonData, i) => (
                <CategoryMenuButtton
                  style={{
                    width: ITEM_WIDTH,
                  }}
                  key={`${buttonData.screen}-${i++}`}
                  imgSrc={buttonData.imgSrc}
                  bgChange={buttonData.bgChange}
                  screen={buttonData.screen}
                  {...buttonData}
                  {...CategoryMenuButtton}
                />
              ))}
            </View>
          )}
                </ScrollView>
        </View>
        <ScrollView
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          alwaysBounceHorizontal={false}
          alwaysBounceVertical={false}
          bounces={false}
          style={{ backgroundColor: schema.backgroundSecondary }}
        >
          <View style={[styles.mainContainer]}>
            <View>
              {/* Category MenuButton Component */}
              <View style={styles.topContainer}>
               
              </View>
              {/* End Catrgory MenuButton Component*/}

              {/* Trending Game Section */}
              {!isMobile ? (
                <Draggable
                  axis="x"
                  onDrag={(e, data) => {
                    handleDrag(e, data, "menu");
                  }}
                  bounds={{ left: -scrollWidth, right: 0 }}
                >
                  <View style={{ flexDirection: "row" }}>
                    {renderLayout()}
                  </View>
                </Draggable>
              ) : (
                <View style={{}}>
                  {renderMobile()}

                </View>
              )}


              <View>
                <View style={styles.secondbox}>
                  <Text style={[styles.secondBoxElement, { color: schema.color }]}>
                    Trending Games
                  </Text>
                </View>
                <View style={styles.cardmenufirst} {...panResponder.panHandlers}>

                  <Carousel
                    data={featuredCard}

                    renderItem={({ item }) => (
                      <FeaturedCard
                        style={{ width: width < 320 ? width : 320 }}
                        gameSrc={item.gameSrc}
                        background={item.background}
                        onGameClick={item.screen}
                        Url={item.Url}
                        gameplay={item.OnPlayerScreen}
                        color={item.color}
                        {...item}
                      />
                    )}
                    sliderWidth={width}
                    itemWidth={320}
                    layout={"default"}
                    layoutCardOffset={10}
                    loop={true}
                    autoplay={true}
                    autoplayInterval={2900}
                    enableSnap={true}
                  />
                </View>
              </View>
              {/* End Trending Game Section */}
              {/* Top Game Section  */}
              <View>
                <View style={styles.secondbox}>
                  <Text style={[styles.secondBoxElement, { color: schema.color }]}>Top Games</Text>
                </View>
                <View>
                  <ScrollView
                    horizontal={true}
                    onLayout={handleLayout}
                    showsVerticalScrollIndicator={false}
                    alwaysBounceVertical={false}
                    style={styles.scrollView}
                  >
                    {!isMobile ? (
                      <Draggable
                        axis="x"
                        onDrag={(e, data) => {
                          handleDrag(e, data, "top");
                        }}
                        bounds={{ left: -scrollWidth, right: 0 }}
                      >
                        <View style={styles.topgames}>
                          {CardsData.slice(0,20).map((CardData, i) => (
                            <TopGameCard
                              key={`${CardData.id}-${i++}`}
                              gameSrc={CardData.gameSrc}
                              background={CardData.background}
                              onGameClick={CardData.screen}
                              Url={CardData.Url}
                              gameplay={CardData.OnPlayerScreen}
                              {...CardData}
                              {...TopGameCard}
                            />
                          ))}
                        </View>
                      </Draggable>
                    ) : (
                      <View style={styles.topgames}>
                        {CardsData.slice(0, 20).map((CardData, i) => (
                          <TopGameCard
                            key={`${CardData.id}-${i++}`}
                            gameSrc={CardData.gameSrc}
                            background={CardData.background}
                            onGameClick={CardData.screen}
                            Url={CardData.Url}
                            gameplay={CardData.OnPlayerScreen}
                            {...CardData}
                            {...TopGameCard}
                          />
                        ))}
                      </View>
                    )}
                  </ScrollView>
                </View>
              </View>
              {/* End Top Game Section  */}

              {/* Quiz Game Card  */}
              <View>
                <View style={styles.secondbox}>
                  <Text style={[styles.secondBoxElement, { color: schema.color }]}>Quiz Games</Text>
                </View>
                <ScrollView
                  horizontal={false}
                  onLayout={handleLayout}
                  showsVerticalScrollIndicator={false}
                  alwaysBounceVertical={true}
                >
                  {!isMobile ? (
                    <Draggable
                      axis="y"
                      onDrag={(e, data) => {
                        handleDrag(e, data, "quiz");
                      }}
                      bounds={{ top: -scrollWidth, bottom: 200 }}
                    >
                      <View>
                        {QuizCardsData.slice(0, 4).map((QuizCardData, i) => (
                          <QuizGame
                            key={`${QuizCardData.id}-${i++}`}
                            gameSrc={QuizCardData.gameSrc}
                            background={QuizCardData.background}
                            onGameClick={QuizCardData.screen}
                            Url={QuizCardData.Url}
                            gameplay={QuizCardData.OnPlayerScreen}
                            {...QuizCardData}
                            {...QuizGame}
                          />
                        ))}
                      </View>
                    </Draggable>
                  ) : (
                    <View>
                      {QuizCardsData.slice(0, 4).map((QuizCardData, i) => (
                        <QuizGame
                          key={`${QuizCardData.id}-${i++}`}
                          gameSrc={QuizCardData.gameSrc}
                          background={QuizCardData.background}
                          onGameClick={QuizCardData.screen}
                          Url={QuizCardData.Url}
                          gameplay={QuizCardData.OnPlayerScreen}
                          {...QuizCardData}
                          {...QuizGame}
                        />
                      ))}
                    </View>
                  )}
                </ScrollView>
              </View>
              {/* End Quiz Game Card  */}

              {/* All Games Section */}
              <View>
                <View style={styles.secondbox}>
                  <Text style={[styles.secondBoxElement, { color: schema.color }]}>All Games</Text>
                </View>
                <View style={{}}>
                  <ScrollView
                    horizontal={false}
                    onLayout={handleLayout}
                    showsVerticalScrollIndicator={false}
                    alwaysBounceVertical={false}
                    style={styles.scrollView}
                  >
                    {/* <ScrollView horizontal={false} alwaysBounceVertical={true}> */}

                    {/* </ScrollView> */}
                    {!isMobile ? (
                      <Draggable
                        axis="y"
                        onDrag={(e, data) => {
                          handleDrag(e, data, "top");
                        }}
                        bounds={{ left: -scrollWidth, right: 0 }}
                      >
                        <View style={{ height: 920 }}>
                          <AutoGrid itemWidth={200}>
                            {CardsData.slice(0,20).map((CardData, i) => (
                              <TopGameCard
                                key={`${CardData.id}-${i++}`}
                                gameSrc={CardData.gameSrc}
                                background={CardData.background}
                                onGameClick={CardData.screen}
                                Url={CardData.Url}
                                gameplay={CardData.OnPlayerScreen}
                                {...CardData}
                                {...TopGameCard}
                              />
                            ))}
                          </AutoGrid>
                        </View>
                      </Draggable>
                    ) : (
                      <View style={{ height: 460 }}>
                        <AutoGrid itemWidth={150}>
                          {CardsData.slice(0,20).map((CardData, i) => (
                            <TopGameCard
                              key={`${CardData.id}-${i++}`}
                              gameSrc={CardData.gameSrc}
                              background={CardData.background}
                              onGameClick={CardData.screen}
                              Url={CardData.Url}
                              gameplay={CardData.OnPlayerScreen}
                              {...CardData}
                              {...TopGameCard}
                            />
                          ))}
                        </AutoGrid>
                      </View>
                    )}
                  </ScrollView>
                </View>
              </View>
              {/* End All Games Section */}
            </View>
            <View style={{ marginBottom: 80 }}></View>
          </View>
        </ScrollView>
       
      </>
    );
  }
);

function useStyles() {
  return StyleSheet.create({
    root: {
      flex: 1,
      flexDirection: "row",
    },
    mainContainer: {
      width: "100%",
      padding: "1rem",
      margin: "auto",
      userSelect: "none",
    },
    topContainer: {
      alignItems: "center",
      flexDirection: "row",
      textTransform: "capitalize",
      padding: 10,
    },
    buttonElements: {
      flexDirection: "row",
      flex: 1,
      width: "100%",
      alignItems: "center",
    },
    secondbox: {
      flex: 1,
      flexDirection: "row",
      padding: 10,
      marginBottom: 10,
      marginTop: 10,
    },
    secondBoxElement: {
      flex: 1,
      fontSize: 25,
      textAlign: "left",
      fontFamily: "Poppins",
      fontWeight: "bold",
    },
    cardmenufirst: {
      flexDirection: "row",
      alignItems: "center",
      alignSelf: "center",
      overflow: "hidden",
    },
    topgames: {
      flex: 1,
      flexDirection: "row",
      height: 220,
      width: 150,
    },
    scrollView: {
      height: "100%",
      scrollbarWidth: 0,
      scrollbarHeight: 1,
      overflow: "scroll",
      "-ms-overflow-style": "none",
    },
  });
}
