import React, { FC, useLayoutEffect,useRef  } from "react"
import { observer } from "mobx-react-lite"
import { View, Platform, Text, StyleSheet, ScrollView, Dimensions, StyleProp, ViewStyle, useColorScheme } from "react-native"
import {  StackScreenProps } from "@react-navigation/stack"
import { AppStackScreenProps } from "../navigators"
import { WebView } from 'react-native-webview';
import { Card } from "@rneui/themed"
import { Appearance } from "react-native-appearance"
import theme from "../theme/theme"
import { useWindowDimensions } from "react-native"
import { useHeaderHeight } from '@react-navigation/elements';

 import { PlayerLeaderBoardData } from "../components/PlayerLeaderBoardData"
 import { useTheme } from "../constant/TheamContext"
// REMOVE ME! ⬇️ This TS ignore will not be necessary after you've added the correct navigator param type
// @ts-ignore
export const GamePlayerViewScreen: FC<StackScreenProps<AppStackScreenProps, "GamePlayerView">> = observer(function GamePlayerViewScreen({ route }) {
    const colorScheme = useColorScheme();
 
    const headerHeight = useHeaderHeight()+0;
    const scrollWidth = 150 * 8;
    const ITEM_WIDTH = 140; // Set the width of each child component
    const { themeColor } = useTheme();
      
      const schema = themeColor === "dark" ? theme.dark : theme.light;
      
    const styles = useStyles()
    const maxScrollWidth = '';
    const { gameplay,Url } = route.params;
    const { width,height } = Dimensions.get('window');
    
    const handleDrag = (e, data) => {
        // If the dragged item has exceeded the maximum scroll limit, set the position to the maximum limit
        const maxScrollWidth = scrollWidth - width;
        console.log(scrollWidth, "width")
        if (data.x < -maxScrollWidth) {
            data.x = -maxScrollWidth
        }
        if (data.x > 0) {
            data.x = 0;
        }
    };
    return (

        
            <View style={[styles.MainContainer, { backgroundColor: 'black' }]}>

                <View style={{ flexDirection: "row" }}>
                    <View style={[styles.GameScreenView, ] }>
                        <iframe src={Url} height={height-headerHeight-5} width={width} style={{ margin: 0,border:0, display:"block" }} />
                    </View>
                </View>
            </View >
    )
})


function useStyles() {
    const { width, height } = useWindowDimensions()

    return StyleSheet.create({
        MainContainer: {
            flex: 1,
            height: height,
            width:'100%',
           
        },
        GameScreenView: {
            // width: (width > 960) ? 600 : "100%",
            // height: (height > 900) ? 700 : 650,
            // marginRight: 0,
            // marginLeft: (width > 960) ? 40 : 0,
        },
        GameNameAndDetailsCard: {
            flex: 1,
            width: "80%",
         },
        topgames: {
            flex: 1,
            flexDirection: "row",
            height: 220,
            width: 150,
        },
        GameName: {
            fontSize: 25,
            marginLeft: 10,
            fontWeight: "700",
        },
        GameDetails: {
            marginTop: 10,
            marginLeft: 30,
            fontSize: 15,

        },
        MoreGamesText: {
            fontSize: 30,
            padding: 20,
            fontWeight: "700",
        },
    })
}