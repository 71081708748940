
import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import { ViewStyle, ScrollView, StyleSheet, View, Text, Image, FlatList, Dimensions, ImageBackground } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { AppStackScreenProps } from "../navigators"
import { Screen } from "../components"
import theme from "../theme/theme"
import { useTheme } from "../constant/TheamContext"
import { Card } from "@rneui/base"
import { isMobile } from "react-device-detect"
// REMOVE ME! ⬇️ This TS ignore will not be necessary after you've added the correct navigator param type
// @ts-ignore
export const LeaderBoardScreen: FC<StackScreenProps<AppStackScreenProps, "LeaderBoard">> = observer(function LeaderBoardScreen() {
    const { themeColor } = useTheme();

    const schema = themeColor === "dark" ? theme.dark : theme.light;

    const styles = useStyles()


    const PlayerData = [
        {
            id: 0,
            PlayerName: "Sam Black",
            PlayerImage: require("../../assets/LeaderBoardImg/lady.png"),
            background: require('../../assets/images/rankBg.png'),
            PlayerRank: "1",
            PlayerBackGround: schema.action,
            TotleGame: 50,
            TotlePoint: 2000,
            Country: "india",
            IdLevel: '45'

        },
        {
            id: 1,
            PlayerName: "Jhan Smith",
            PlayerImage: require("../../assets/LeaderBoardImg/man.png"),
            background: require('../../assets/images/rankBg2.png'),
            PlayerRank: "2",
            PlayerBackGround: schema.adventure,
            TotleGame: 50,
            TotlePoint: 2000,
            Country: "india",
            IdLevel: '40'
        },
        {
            id: 2,
            PlayerName: "Jessice Char",
            PlayerImage: require("../../assets/LeaderBoardImg/women.png"),
            PlayerRank: "3",
            background: require('../../assets/images/rankBg3.png'),
            PlayerBackGround: schema.board,
            TotleGame: 50,
            TotlePoint: 2000,
            Country: "india",
            IdLevel: '65'
        },

        {
            id: 3,
            PlayerName: "Jessice Char",
            PlayerImage: require("../../assets/LeaderBoardImg/littleLady.png"),
            PlayerRank: "4",
            PlayerBackGround: schema.card,
            TotleGame: 50,
            TotlePoint: 2000,
            Country: "india",
            IdLevel: '45'
        },

        {
            id: 4,
            PlayerName: "Jessice Char",
            PlayerImage: require("../../assets/LeaderBoardImg/littleLady.png"),
            PlayerRank: "5",
            PlayerBackGround: schema.casino,
            TotleGame: 50,
            TotlePoint: 2000,
            Country: "india",
            IdLevel: '45'

        },
        {
            id: 5,
            PlayerName: "Jessice Char",
            PlayerImage: require("../../assets/LeaderBoardImg/littleLady.png"),
            PlayerRank: "6",
            PlayerBackGround: schema.Shooting,
            TotleGame: 50,
            TotlePoint: 2000,
            Country: "india",
            IdLevel: '45'

        },

        {
            id: 6,
            PlayerName: "Jessice Char",
            PlayerImage: require("../../assets/LeaderBoardImg/littleLady.png"),
            PlayerRank: "7",
            PlayerBackGround: schema.puzzle,
            TotleGame: 50,
            TotlePoint: 2000,
            Country: "india",
            IdLevel: '45'

        },
        {
            id: 7,
            PlayerName: "Jessice Char",
            PlayerImage: require("../../assets/LeaderBoardImg/littleLady.png"),
            PlayerRank: "8",
            PlayerBackGround: schema.strategy,
            TotleGame: 50,
            TotlePoint: 2000,
            Country: "india",
            IdLevel: '45'

        },
        {
            id: 8,
            PlayerName: "Jessice Char",
            PlayerImage: require("../../assets/LeaderBoardImg/littleLady.png"),
            PlayerRank: "9",
            PlayerBackGround: schema.memory,
            TotleGame: 50,
            TotlePoint: 2000,
            Country: "india",
            IdLevel: '45'

        },
        {
            id: 9,
            PlayerName: "Jessice Char",
            PlayerImage: require("../../assets/LeaderBoardImg/littleLady.png"),
            PlayerRank: "10",
            PlayerBackGround: schema.Number,
            TotleGame: 50,
            TotlePoint: 2000,
            Country: "india",
            IdLevel: '45'

        },
    ]
    return (
        <Screen style={[$root, {
            backgroundColor: schema.backgroundSecondary,
        }]} preset="scroll">
            <ScrollView
                showsVerticalScrollIndicator={false}
                alwaysBounceHorizontal={false}
                alwaysBounceVertical={false}
                bounces={false}
                style={{ backgroundColor: schema.backgroundSecondary }}
            >
                <View style={[styles.MainContainer, { backgroundColor: schema.backgroundSecondary }]}>
                    <ScrollView>

                        <View style={styles.Container}>

                            {!isMobile ? (<>
                                <Card containerStyle={{ backgroundColor: '#35354B', border: 'none', borderRadius: 10, padding: 0 }}>

                                    <View style={{ padding: 10 }}>
                                        <Text style={{ color: 'white', fontSize: 20, fontWeight: "700", }}>
                                            TOP USER BY <span style={{ color: '#45CFDD' }}>ALL-TIME</span> VOLIME
                                        </Text>
                                    </View>
                                    <View style={{ width: "100%", backgroundColor: 'white', height: 1 }}></View>
                                    <View style={{ justifyContent: 'center', alignItems: 'center', marginTop: 20, marginBottom: 20 }}>
                                        <View style={{ width: "95%", backgroundColor: '#434367', padding: 10, borderRadius: 20, flexDirection: 'row', justifyContent: 'space-between', }}>
                                            <View>
                                                <Text style={{ color: '#8CB3C2' }}>Rank</Text>
                                            </View>
                                            <View>
                                                <Text style={{ color: '#8CB3C2' }}>User Name</Text>
                                            </View>
                                            <View>
                                                <Text style={{ color: '#8CB3C2' }}>Total Game</Text>
                                            </View>
                                            <View>
                                                <Text style={{ color: '#8CB3C2' }}>Total Points</Text>
                                            </View>
                                            <View>
                                                <Text style={{ color: '#8CB3C2' }}>Level ID</Text>
                                            </View>
                                            <View>
                                                <Text style={{ color: '#8CB3C2' }}>Region</Text>
                                            </View>
                                        </View>


                                        {PlayerData.map((PlayerData, i) => (i < 3 &&

                                            <View key={`${PlayerData.id}`} style={{ width: "98%", backgroundColor: '#434367', flexDirection: 'row', marginTop: 20 }}>

                                                <View style={{ width: "19%" }}>
                                                    <ImageBackground source={PlayerData.background} style={{ width: "100%" }}>
                                                        <View style={{ width: 50, height: 40, marginLeft: 20, marginTop: 10 }}>
                                                            <ImageBackground source={require('../../assets/images/rank.png')} style={{ width: 40, height: 30, alignItems: "center", justifyContent: 'center' }} resizeMode="contain">
                                                                <Text style={{ color: 'white' }}>{PlayerData.PlayerRank}</Text>
                                                            </ImageBackground>
                                                        </View>
                                                    </ImageBackground>
                                                </View>

                                                <View style={{ width: "20%" }}>
                                                    <View style={{ width: 310, marginTop: 5, flexDirection: 'row', alignItems: 'center', }}>

                                                        <Image source={PlayerData.PlayerImage} style={{ width: 40, height: 40, borderRadius: 5 }} />

                                                        <Text style={{ color: 'white', fontSize: 16, marginLeft: 10 }}>{PlayerData.PlayerName}</Text>
                                                    </View>
                                                </View>

                                                <View style={{ width: "19%" }}>
                                                    <Text style={{ color: 'white', marginTop: 15 }}>{PlayerData.TotleGame}</Text>
                                                </View>

                                                <View style={{ width: "19%" }}>
                                                    <Text style={{ color: 'white', marginTop: 15 }}>{PlayerData.TotlePoint}</Text>
                                                </View>

                                                <View style={{ width: "18%" }}>
                                                    <Text style={{ color: 'white', marginTop: 15 }}>{PlayerData.IdLevel}</Text>
                                                </View>

                                                <View style={{ width: "19%" }}>
                                                    <Text style={{ color: 'white', marginTop: 15, }}>{PlayerData.Country}</Text>
                                                </View>



                                            </View>
                                        ))}


                                        {PlayerData.map((PlayerData, i) => (i >= 3 &&

                                            <View key={`${PlayerData.id}`} style={{ width: "98%", backgroundColor: '#434367', flexDirection: 'row', marginTop: 20 }}>

                                                <View style={{ width: "19%" }}>
                                                    <View style={{ width: 50, height: 40, marginLeft: 10, alignItems: "center", justifyContent: 'center' }}>
                                                        <Text style={{ color: 'white' }}>{PlayerData.PlayerRank}</Text>
                                                    </View>
                                                </View>

                                                <View style={{ width: "20%", }}>
                                                    <View style={{ width: 310, marginTop: 5, flexDirection: 'row', alignItems: 'center', }}>

                                                        <Image source={PlayerData.PlayerImage} style={{ width: 40, height: 40, borderRadius: 5 }} />

                                                        <Text style={{ color: 'white', fontSize: 16, marginLeft: 10 }}>{PlayerData.PlayerName}</Text>
                                                    </View>
                                                </View>
                                                <View style={{ width: "19%", marginTop: 15 }}>
                                                    <Text style={{ color: 'white' }}>{PlayerData.TotleGame}</Text>
                                                </View>

                                                <View style={{ width: "19%", marginTop: 15 }}>
                                                    <Text style={{ color: 'white' }}>{PlayerData.TotlePoint}</Text>
                                                </View>

                                                <View style={{ width: "18%", marginTop: 15 }}>
                                                    <Text style={{ color: 'white' }}>{PlayerData.IdLevel}</Text>
                                                </View>

                                                <View style={{ width: "19%", marginTop: 15 }}>
                                                    <Text style={{ color: 'white' }}>{PlayerData.Country}</Text>
                                                </View>
                                            </View>
                                        ))}
                                    </View>

                                </Card>
                            </>) : (<>
                                <Card containerStyle={{ backgroundColor: '#35354B', border: 'none', borderRadius: 10, padding: 0 }}>

                                    <View style={{ padding: 10 }}>
                                        <Text style={{ color: 'white', fontSize: 20, fontWeight: "700", }}>
                                            TOP USER BY <span style={{ color: '#45CFDD' }}>ALL-TIME</span> VOLIME
                                        </Text>
                                    </View>
                                    <View style={{ width: "100%", backgroundColor: 'white', height: 1 }}></View>
                                   
                                    <View style={{ justifyContent: 'center', alignItems: 'center', marginTop: 20, marginBottom: 20 }}>
                                        <View style={{ width: "95%", backgroundColor: '#434367', padding: 10, borderRadius: 20, flexDirection: 'row', justifyContent: 'space-between', }}>
                                            <View>
                                                <Text style={{ color: '#8CB3C2' }}>Rank</Text>
                                            </View>
                                            <View>
                                                <Text style={{ color: '#8CB3C2' }}>User Name</Text>
                                            </View>
                                            <View style={{marginLeft:20}}>
                                                <Text style={{ color: '#8CB3C2' }}>Total Game</Text>
                                            </View>
                                            <View>
                                                <Text style={{ color: '#8CB3C2' }}>Total Points</Text>
                                            </View>
                                           
                                        </View>


                                        {PlayerData.map((PlayerData, i) => (i < 3 &&

                                            <View key={`${PlayerData.id}`} style={{ width: "98%", backgroundColor: '#434367', flexDirection: 'row', marginTop: 20 }}>

                                                <View style={{ width: "20%" }}>
                                                    <ImageBackground source={PlayerData.background} style={{ width: "100%" }}>
                                                        <View style={{ width: 50, height: 40,  marginTop: 10 }}>
                                                            <ImageBackground source={require('../../assets/images/rank.png')} style={{ width: 40, height: 30, alignItems: "center", justifyContent: 'center' }} resizeMode="contain">
                                                                <Text style={{ color: 'white' }}>{PlayerData.PlayerRank}</Text>
                                                            </ImageBackground>
                                                        </View>
                                                    </ImageBackground>
                                                </View>

                                                <View style={{ width: "40%" }}>
                                                    <View style={{ width: 310, marginTop: 5, flexDirection: 'row', alignItems: 'center', }}>

                                                        <Image source={PlayerData.PlayerImage} style={{ width: 40, height: 40, borderRadius: 5 }} />

                                                        <Text style={{ color: 'white', fontSize: 16, marginLeft: 10 }}>{PlayerData.PlayerName}</Text>
                                                    </View>
                                                </View>

                                                <View style={{ width: "20%" }}>
                                                    <Text style={{ color: 'white', marginTop: 15 }}>{PlayerData.TotleGame}</Text>
                                                </View>

                                                <View style={{ width: "19%" }}>
                                                    <Text style={{ color: 'white', marginTop: 15 }}>{PlayerData.TotlePoint}</Text>
                                                </View>

                                               



                                            </View>
                                        ))}


                                        {PlayerData.map((PlayerData, i) => (i >= 3 &&

                                            <View key={`${PlayerData.id}`} style={{ width: "98%", backgroundColor: '#434367', flexDirection: 'row', marginTop: 20 }}>

                                                <View style={{ width: "19%" }}>
                                                    <View style={{ width: 50, height: 40, marginLeft: 10, alignItems: "center", justifyContent: 'center' }}>
                                                        <Text style={{ color: 'white' }}>{PlayerData.PlayerRank}</Text>
                                                    </View>
                                                </View> 

                                                <View style={{ width: "40%", }}>
                                                    <View style={{ width: 310, marginTop: 5, flexDirection: 'row', alignItems: 'center', }}>

                                                        <Image source={PlayerData.PlayerImage} style={{ width: 40, height: 40, borderRadius: 5 }} />

                                                        <Text style={{ color: 'white', fontSize: 16, marginLeft: 10 }}>{PlayerData.PlayerName}</Text>
                                                    </View>
                                                </View>
                                                <View style={{ width: "19%", marginTop: 15 }}>
                                                    <Text style={{ color: 'white' }}>{PlayerData.TotleGame}</Text>
                                                </View>

                                                <View style={{ width: "19%", marginTop: 15 }}>
                                                    <Text style={{ color: 'white' }}>{PlayerData.TotlePoint}</Text>
                                                </View>
                                            </View>
                                        ))}
                                    </View>

                                </Card>
                            </>)

                            }



                        </View>
                    </ScrollView>
                    <View style={{ height: 90, }}>

                    </View>
                </View>
            </ScrollView>
        </Screen>
    )
})
const $root: ViewStyle = {
    flex: 1,

}
function useStyles() {
    // const { width, height } = useWindowDimensions()
    return StyleSheet.create({
        MainContainer: {
            width: "100%",

        },
        Container: {
            // width: (width > 500) ? "50%" : "100%",
            width: "100%",

        },

    })
}