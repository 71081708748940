import * as React from "react";
import { StyleProp, TextStyle, View, ViewStyle, Image, TouchableOpacity, StyleSheet } from "react-native";
import { observer } from "mobx-react-lite";
import { colors, typography } from "../theme";
import { Text } from "./Text";
import { Card } from "@rneui/themed";
import { useNavigation } from "@react-navigation/native";

export interface TopGameCardProps {
  /** An optional style override useful for padding & margin. */
  style?: StyleProp<ViewStyle>;
  gameName: string;
  gameSrc: any;
  background: string;
  title: string;
  onGameClick: string;
  Url: string;
  gameplay:string
}

export const TopGameCard = observer(function TopGameCard(props: TopGameCardProps) {
  const { style, gameName, gameSrc, background, title, onGameClick, Url, gameplay, } = props;
  const $styles = [$container, style];
  const navigation = useNavigation();
  const playingCount = Math.floor(Math.random() * (2500 - 100 + 1)) + 100; 
  console.log("Gamelay>>>>>>",gameplay)
  return (
    <View>
      <TouchableOpacity onPress={() => navigation.navigate(onGameClick, { Url, gameName, gameSrc, gameplay})}>
        <Card containerStyle={[styles.topgames, { backgroundColor: background }]}>
          <Image source={gameSrc} style={styles.topgamemid} />
          <View style={styles.topGameBtnText}>
            <View style={styles.gameText}>
              <Text style={styles.topgamecardtext}>{title}</Text>
            </View>
            <View />
          </View>
          <Text style={styles.topgamelastText}>Playing: {playingCount}</Text>
        </Card>
      </TouchableOpacity>
    </View>
  );
});

const $container: ViewStyle = {
  justifyContent: "center",
};
const $text: TextStyle = {
  fontFamily: typography.primary.normal,
  fontSize: 14,
  color: colors.palette.primary500,
};

const styles = StyleSheet.create({
  topgames: {
    flex: 1,
    flexDirection: "row",
    borderRadius: 15,
    width: 150,
    height: 260,
    margin: 10,
    padding: 10,
    borderWidth: 0,
    elevation: 10,
  },
  gameText: {
    flex: 1,
  },
  topgamemid: {
    width: 130,
    height: 130,
    borderRadius: 12,
    alignSelf: "center",
  },
  topGameBtnText: {
    flexDirection: "row",
    alignItems: "flex-end",
    marginTop: 3,
    fontWeight: "bold",
  },
  topgamecardtext: {
    color: "white",
    fontWeight: "bold",
    fontSize: 14,
    fontFamily: "poppins",
  },
  topgamelastText: {
    color: "white",
    fontWeight: "bold",
    fontSize: 14,
    fontFamily: "poppins",
  },
});
