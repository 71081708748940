import {
    DarkTheme,
    DefaultTheme,
    NavigationContainer,
    NavigatorScreenParams, // @demo remove-current-line
} from "@react-navigation/native"
import { createNativeStackNavigator } from "@react-navigation/native-stack"
import { StackScreenProps } from "@react-navigation/stack"
import { observer } from "mobx-react-lite"
import Config from "../config"
import { useStores } from "../models" // @demo remove-current-line
import { navigationRef, useBackButtonHandler } from "./navigationUtilities"
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs"
import { EventRegister } from 'react-native-event-listeners'
import React, { useState, useEffect } from 'react';
  import { StackSceenNevigationNavigator } from "./StackSceenNevigationNavigator"
 import { Appearance } from 'react-native-appearance';
import theme from "../theme/theme"
import {
    SafeAreaProvider,
    useSafeAreaInsets,
  } from 'react-native-safe-area-context';
import { ThemeProvider } from "../constant/TheamContext"
import { Card } from "@rneui/base"
/**
 * This type allows TypeScript to know what routes are defined in this navigator
 * as well as what properties (if any) they might take when navigating to them.
 *
 * If no params are allowed, pass through `undefined`. Generally speaking, we
 * recommend using your MobX-State-Tree store(s) to keep application state
 * rather than passing state through navigation params.
 *
 * For more information, see this documentation:
 *   https://reactnavigation.org/docs/params/
 *   https://reactnavigation.org/docs/typescript#type-checking-the-navigator
 *   https://reactnavigation.org/docs/typescript/#organizing-types
 */
export type AppStackParamList = {
    WelcomeScreen: undefined
    Login: undefined // @demo remove-current-line
    Demo: NavigatorScreenParams<DemoTabParamList>
    LeaderBoardScreen: undefined
    SpendingTimeScreen: undefined
    ActionsScreen: undefined
    HomeScreen: undefined
    SessionsScreen: undefined
    TrophiesScreen: undefined
    // @demo remove-current-line
    // 🔥 Your screens go here
}

const Tab = createBottomTabNavigator<NavigatorScreenParams>()
/**
 * This is a list of all the route names that will exit the app if the back button
 * is pressed while in that screen. Only affects Android.
 */

 


const exitRoutes = Config.exitRoutes

export type AppStackScreenProps<T extends keyof AppStackParamList> = StackScreenProps<
    AppStackParamList,
    T
>
// Documentation: https://reactnavigation.org/docs/stack-navigator/
const Stack = createNativeStackNavigator()

const AppStack = observer(function AppStack() {
    // @demo remove-block-start
    const {
        authenticationStore: { isAuthenticated },
    } = useStores()
     // @demo remove-block-end
    return (

        <ThemeProvider>
            <SafeAreaProvider>
            <StackSceenNevigationNavigator></StackSceenNevigationNavigator>
            </SafeAreaProvider>
        </ThemeProvider>
 
     )
})

interface NavigationProps extends Partial<React.ComponentProps<typeof NavigationContainer>> { }

export const AppNavigator = observer(function AppNavigator(props: NavigationProps) {
    // const colorScheme = useColorScheme()
    

    useBackButtonHandler((routeName) => exitRoutes.includes(routeName))

    return (
        
      
        <NavigationContainer
        documentTitle={{
             formatter: (options, route) => 
              `P9Games.com || Top Games `,
          }}

            {...props}
            ref={navigationRef}
         >
            <AppStack />
        </NavigationContainer>
        

    )
});

