import React, { FC, useContext,useState } from "react"
import { observer } from "mobx-react-lite"
import { ViewStyle, View , StyleSheet, Switch} from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { AppStackScreenProps } from "../navigators"
import { Screen, Text } from "../components"
import themeContext from "../theme/themeContext"
// import { useNavigation } from "@react-navigation/native"
// import { useStores } from "../models"
import { fc ,qc ,bd , cd} from "../constant/imagePath"
import AutoGrid from "../components/Autogrid"
import { useTheme } from "../constant/TheamContext"
// STOP! READ ME FIRST!
// To fix the TS error below, you'll need to add the following things in your navigation config:
// - Add `LeaderBoard: undefined` to AppStackParamList
// - Import your screen, and add it to the stack:
//     `<Stack.Screen name="LeaderBoard" component={LeaderBoardScreen} />`
// Hint: Look for the 🔥!

// REMOVE ME! ⬇️ This TS ignore will not be necessary after you've added the correct navigator param type
// @ts-ignore
export const TrophiesScreen: FC<StackScreenProps<AppStackScreenProps, "Trophies">> = observer(function TrophiesScreen() {
 const theme = useContext(themeContext);
 const { themeColor } = useTheme();
      
 const schema = themeColor === "dark" ? theme.dark : theme.light;
 
  // Pull in one of our MST stores
  // const { someStore, anotherStore } = useStores()

  // Pull in navigation via hook
  // const navigation = useNavigation()
  return (
    <View
        style={[styles.container, {backgroundColor: theme.backgroundColor}]}>
        <Text style={[styles.text, {color:theme.color}]}>Trophies
        </Text>
      </View>
  )
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  text:{
    fontSize:30,
    fontWeight:'bold'
  }
})

