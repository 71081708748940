import React from 'react';
import { View, StyleSheet, useWindowDimensions } from 'react-native';

interface AutoGridProps {
  itemWidth: number;
  children: React.ReactNode;

}

const AutoGrid: React.FC<AutoGridProps> = ({ children, itemWidth }) => {
  const windowWidth = useWindowDimensions().width;
  const numColumns = Math.floor(windowWidth / itemWidth);

  const renderItems = () => {
    const rows = [];
    let row = [];
    
    React.Children.forEach(children, (child, index) => {
      if (index !== 0 && index % numColumns === 0) {
        rows.push(<View style={styles.row}>{row}</View>);
        row = [];
      }
      row.push(
        <View style={[styles.item, { width: `${100 / numColumns}%` }]}>
          {child}
        </View>
      );
    });

    if (row.length > 0) {
      rows.push(<View style={styles.row}>{row}</View>);
    }

    return rows;
  };

  return <View style={styles.container}>{renderItems()}</View>;
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  row: {
    flexDirection: 'row',
   },
  item: {
    padding: 10,
  },
});

export default AutoGrid;
