const theme = {
  light: {
    theme: "light",
    shadow:"#cfcfcf",
    color: "black",
    action: "#C32309",
    adventure: "#0C5DC9",
    board: "#1D8C16",
    card: "#FDB801",
    casino: "#024393",
    memory: "#13C457",
    puzzle: "#F88EB6",
    strategy: "#FF861D",
    Quiz: "#C32309",
    Shooting: "#0C5DC9",
    Sport: "#1D8C16",
    Survival: "#FDB801",
    Number: "#024393",
    backgroundPrimary: "white",
    backgroundSecondary: "white",
    leaderboardFocus: require("../../assets/icons/leaderboard.png"),
    leaderboard: require("../../assets/icons/leaderboard.png"),
    activityFocus: require("../../assets/icons/hoursTime.png"),
    activity: require("../../assets/icons/hoursTime.png"),
    homeFocus: require("../../assets/icons/home.png"),
    home: require("../../assets/icons/home.png"),
    sessionsFocus: require("../../assets/icons/sessions.png"),
    sessions: require("../../assets/icons/sessions.png"),
    trophiesFocus: require("../../assets/icons/trophies.png"),
    trophies: require("../../assets/icons/trophies.png"),
    logo: require("../../assets/images/PrimeGame.png"),
    user:require("../../assets/images/profile.png"),
    profile:require("../../assets/images/user.png")
  },
  dark: {
    theme: "dark",
    shadow:"black",
    color: "white",
    action: "#C32309",
    adventure: "#0C5DC9",
    board: "#1D8C16",
    card: "#FDB801",
    casino: "#024393",
    memory: "#13C457",
    puzzle: "#AA336A",
    strategy: "#FF861D",
    Quiz: "#C32309",
    Shooting: "#0C5DC9",
    Sport: "#1D8C16",
    Survival: "#FDB801",
    Number: "#024393",
    backgroundPrimary: "#171717",
    backgroundSecondary: "#303030",
    leaderboardFocus: require("../../assets/icons/leaderboard.png"),
    leaderboard: require("../../assets/icons/unactiveLeaderboard.png"),
    activityFocus: require("../../assets/icons/hoursTime.png"),
    activity: require("../../assets/icons/unactiveHoursTime.png"),
    homeFocus: require("../../assets/icons/home.png"),
    home: require("../../assets/icons/unactiveHome.png"),
    sessionsFocus: require("../../assets/icons/sessions.png"),
    sessions: require("../../assets/icons/unactiveSessions.png"),
    trophiesFocus: require("../../assets/icons/trophies.png"),
    trophies: require("../../assets/icons/unactiveTrophies.png"),
    logo: require("../../assets/images/PrimeGameDarkMode.png"),
    user:require("../../assets/images/profile.png"),
    profile:require("../../assets/images/user.png")
  },
}

export default theme;
