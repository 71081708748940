import React from 'react';
import { View, Image, Text, StyleSheet, Dimensions, TouchableOpacity } from 'react-native';
import { useNavigation } from "@react-navigation/native"

interface Props {
    gameName: string;
    gameSrc: any;
    background: string;
    title: string;
    onGameClick: string;
    Url: string;
    gameplay: string
    style
    color

}

const FeaturedCard: React.FC<Props> = ({ color, style, gameName, gameSrc, background, title, onGameClick, Url, gameplay }) => {
    const navigation = useNavigation()
    return (
        <>
            <View>
                <View style={[styles.cardContainer, { width: style.width - 20}]}>
                    <TouchableOpacity onPress={() => navigation.navigate(onGameClick, { Url, gameName, gameSrc, gameplay })}>
                        <Image style={[styles.cardImage, ]} source={gameSrc} />

                        <View style={[styles.waveOverlay, { backgroundColor: background }]}>
                            <Text style={[styles.cardText, { color: color }]}>{title}</Text>
                        </View>
                    </TouchableOpacity>
                </View>
            </View>
        </>
    );
};



const styles = StyleSheet.create({
    cardContainer: {
        flex: 1,
        borderWidth: 1,
        alignSelf: 'center',
        borderRadius: 20,
        overflow: 'hidden',


    },
    cardImage: {
        width:'100%',
        height:250,
        borderTopEndRadius: 12,
        borderTopLeftRadius: 12,
        resizeMode: 'stretch',
        aspectRatio:2/4
        
    },
    waveOverlay: {
        height: 60,
        opacity: 0.8,
    },
    cardText: {
        fontSize: 16,
        fontWeight: 'bold',
        padding: 20,
        opacity: 1,
        alignSelf: 'center',
    },
});

export default FeaturedCard;
