export default {

    leaderboard: require('../../assets/icons/leaderboard.png'),
    unactiveLeaderboard: require('../../assets/icons/unactiveLeaderboard.png'),
    hoursTime: require('../../assets/icons/hoursTime.png'),
    unactiveHoursTime: require('../../assets/icons/unactiveHoursTime.png'),
    home: require('../../assets/icons/home.png'),
    unactiveHome: require('../../assets/icons/unactiveHome.png'),
    sessions: require('../../assets/icons/sessions.png'),
    unactiveSessions: require('../../assets/icons/unactiveSessions.png'),
    trophies: require('../../assets/icons/trophies.png'),
    unactiveTrophies: require('../../assets/icons/unactiveTrophies.png'),
    logo: require("../../assets/images/PrimeGame.png")

}
export const fc = (schema: any): any[] => {

    return [
        {
            id: 0,
            gameName: "Candy Absolute",
            gameSrc: require("../../assets/games-icon/1.webp"),
            bgColor: "#ff0000",
            screen: "GameScreen",
            title: "Candy Absolute",
            background: schema.adventure,
            color: schema.color,
            OnPlayerScreen: 'GamePlayerViewScreen',
            Url: "https://www.cdn1.p9games.com/dunkdash/index.html",
            gameType: 'Top Games'
        },
        {
            id: 1,
            gameName: "Cosmo Scape",
            gameSrc: require("../../assets/games-icon/2.webp"),
            bgColor: "#04E255",
            Url: "https://www.cdn1.p9games.com/cosmoscape/index.html",
            screen: "GameScreen",
            title: "Cosmo Scape",
            OnPlayerScreen: 'GamePlayerViewScreen',
            background: schema.puzzle,
            color: schema.color,
            gameType: 'Tranding Card',
        },
        {
            id: 2,
            gameName: "Drag'n Ball",
            gameSrc: require("../../assets/games-icon/3.webp"),
            bgColor: "#ff0000",
            Url: "http://games.unanimoustech.com/dragnball",
            screen: "GameScreen",
            title: "Drag'n Ball",
            OnPlayerScreen: 'GamePlayerViewScreen',
            background: schema.strategy,
            color: schema.color,
        },
        {
            id: 3,
            gameName: "Dunk Dash",
            gameSrc: require("../../assets/games-icon/4.webp"),
            bgColor: "#04E255",
            Url: "https://www.cdn1.p9games.com/dunkdash/index.html",
            screen: "GameScreen",
            title: "Dunk Dash",
            OnPlayerScreen: 'GamePlayerViewScreen',
            background: schema.board,
            color: schema.color,
        },
        {
            id: 4,
            gameName: "Earth Invasion",
            gameSrc: require("../../assets/games-icon/5.webp"),
            bgColor: "#ff0000",
            Url: "https://www.cdn1.p9games.com/earthinvasion/index.html",
            screen: "GameScreen",
            title: "Earth Invasion",
            OnPlayerScreen: 'GamePlayerViewScreen',
            background: schema.action,
            color: schema.color,
        },
        {
            id: 5,
            gameName: "Face Busting Frenzy",
            gameSrc: require("../../assets/games-icon/6.webp"),
            bgColor: "#ff0000",
            screen: "GameScreen",
            title: "Face Busting Frenzy",
            background: schema.adventure,
            OnPlayerScreen: 'GamePlayerViewScreen',
            color: schema.color,
            Url: "https://www.cdn1.p9games.com/facebustinfrenzy/index.html",
        },
        {
            id: 6,
            gameName: "Fall or Build",
            gameSrc: require("../../assets/games-icon/7.webp"),
            bgColor: "#04E255",
            Url: "https://www.cdn1.p9games.com/owlflight/index.html",
            screen: "GameScreen",
            title: "Fall or Build",
            OnPlayerScreen: 'GamePlayerViewScreen',
            background: schema.puzzle,
            color: schema.color,
        },
        {
            id: 7,
            gameName: "Feed the pup",
            gameSrc: require("../../assets/games-icon/8.webp"),
            bgColor: "#ff0000",
            Url: "https://www.cdn1.p9games.com/feedthepup/index.html",
            screen: "GameScreen",
            title: "Feed the pup",
            OnPlayerScreen: 'GamePlayerViewScreen',
            background: schema.strategy,
            color: schema.color,
        },
        {
            id: 8,
            gameName: "Fist Fury",
            gameSrc: require("../../assets/games-icon/9.webp"),
            bgColor: "#04E255",
            Url: "https://www.cdn1.p9games.com/fistfury/index.html",
            screen: "GameScreen",
            title: "Fist Fury",
            OnPlayerScreen: 'GamePlayerViewScreen',
            background: schema.board,
            color: schema.color,
        },
        {
            id: 9,
            gameName: "Flaapy Fin",
            gameSrc: require("../../assets/games-icon/10.webp"),
            bgColor: "#ff0000",
            Url: "https://www.cdn1.p9games.com/flappyfins/index.html",
            screen: "GameScreen",
            title: "Flaapy Fin",
            OnPlayerScreen: 'GamePlayerViewScreen',
            background: schema.action,
            color: schema.color,
        },



        {
            id: 11,
            gameName: "Fruitopia Madness",
            gameSrc: require("../../assets/games-icon/11.webp"),
            bgColor: "#ff0000",
            screen: "GameScreen",
            title: "Fruitopia Madness",
            background: schema.adventure,
            OnPlayerScreen: 'GamePlayerViewScreen',
            color: schema.color,
            Url: "https://www.cdn1.p9games.com/fruitopiamadness/index.html",
        },
        {
            id: 12,
            gameName: "Happy Swap",
            gameSrc: require("../../assets/games-icon/12.webp"),
            bgColor: "#04E255",
            Url: "https://www.cdn1.p9games.com/happyswap/index.html",
            screen: "GameScreen",
            title: "Happy Swap",
            OnPlayerScreen: 'GamePlayerViewScreen',
            background: schema.puzzle,
            color: schema.color,
        },
        {
            id: 13,
            gameName: "Hole Hustle",
            gameSrc: require("../../assets/games-icon/13.webp"),
            bgColor: "#ff0000",
            Url: "https://www.cdn1.p9games.com/holehustle/index.html",
            screen: "GameScreen",
            title: "Hole Hustle",
            OnPlayerScreen: 'GamePlayerViewScreen',
            background: schema.strategy,
            color: schema.color,
        },
        {
            id: 14,
            gameName: "Hooked On Fish",
            gameSrc: require("../../assets/games-icon/14.webp"),
            bgColor: "#04E255",
            Url: "https://www.cdn1.p9games.com/hookedonfish/index.html",
            screen: "GameScreen",
            title: "Hooked On Fish",
            OnPlayerScreen: 'GamePlayerViewScreen',
            background: schema.board,
            color: schema.color,
        },
        {
            id: 15,
            gameName: "Hungry Rattle",
            gameSrc: require("../../assets/games-icon/15.webp"),
            bgColor: "#ff0000",
            Url: "https://www.cdn1.p9games.com/hungryrattle/index.html",
            screen: "GameScreen",
            title: "Hungry Rattle",
            OnPlayerScreen: 'GamePlayerViewScreen',
            background: schema.action,
            color: schema.color,
        },
        {
            id: 16,
            gameName: "Laser Escape",
            gameSrc: require("../../assets/games-icon/16.webp"),
            bgColor: "#ff0000",
            screen: "GameScreen",
            title: "Laser Escape",
            background: schema.adventure,
            OnPlayerScreen: 'GamePlayerViewScreen',
            color: schema.color,
            Url: "https://www.cdn1.p9games.com/Laserescape/index.html",
        },
        {
            id: 17,
            gameName: "Maths Riddle Master",
            gameSrc: require("../../assets/games-icon/17.webp"),
            bgColor: "#04E255",
            Url: "https://www.cdn1.p9games.com/mathsriddlemaster/index.html",
            screen: "GameScreen",
            title: "Maths Riddle Master",
            OnPlayerScreen: 'GamePlayerViewScreen',
            background: schema.puzzle,
            color: schema.color,
        },
        {
            id: 18,
            gameName: "Owl Flight",
            gameSrc: require("../../assets/games-icon/18.webp"),
            bgColor: "#ff0000",
            Url: "https://www.cdn1.p9games.com/owlflight/index.html",
            screen: "GameScreen",
            title: "Owl Flight",
            OnPlayerScreen: 'GamePlayerViewScreen',
            background: schema.strategy,
            color: schema.color,
        },
        {
            id: 19,
            gameName: "Pool Prodigy",
            gameSrc: require("../../assets/games-icon/19.webp"),
            bgColor: "#04E255",
            Url: "https://www.cdn1.p9games.com/poolprodigy/index.html",
            screen: "GameScreen",
            title: "Pool Prodigy",
            OnPlayerScreen: 'GamePlayerViewScreen',
            background: schema.board,
            color: schema.color,
        },
        {
            id: 20,
            gameName: "Pop Pop Ballons",
            gameSrc: require("../../assets/games-icon/20.webp"),
            bgColor: "#ff0000",
            Url: "https://www.cdn1.p9games.com/poppopballons/index.html",
            screen: "GameScreen",
            title: "Pop Pop Ballons",
            OnPlayerScreen: 'GamePlayerViewScreen',
            background: schema.action,
            color: schema.color,
        },
    ]
}

    export const bd = (schema: any): any[] => {
        return [
            {
                textData: "Action",
                imgSrc: require("../../assets/images/Actions.png"),
                bgChange: schema.action,
                screen: "Action",
            },
            {
                textData: "Adventure",
                imgSrc: require("../../assets/images/Adventures.png"),
                bgChange: schema.adventure,
                screen: "Action",
            },
            {
                textData: "Board",
                imgSrc: require("../../assets/images/Board.png"),
                bgChange: schema.board,
                screen: "Action",
            },
            {
                textData: "Card",
                imgSrc: require("../../assets/images/Card.png"),
                bgChange: schema.card,
                screen: "Action",
            },
            {
                textData: "Casino",
                imgSrc: require("../../assets/images/Casino.png"),
                bgChange: schema.casino,
                screen: "Action",
            },
            {
                textData: "Memory",
                imgSrc: require("../../assets/images/Memory.png"),
                bgChange: schema.memory,
                screen: "Action",
            },
            {
                textData: "Puzzle",
                imgSrc: require("../../assets/images/Puzzle.png"),
                bgChange: schema.puzzle,
                screen: "Action",
            },
            {
                textData: "Strategy",
                imgSrc: require("../../assets/images/Puzzle.png"),
                bgChange: schema.strategy,
                screen: "Action",
            },

            {
                textData: 'Quiz',
                imgSrc: require('../../assets/images/Quiz.png'),
                bgChange: schema.Quiz,
                screen: "Action"
            },
            {
                textData: 'Shooting',
                imgSrc: require('../../assets/images/Shooting.png'),
                bgChange: schema.Shooting,
                screen: "Action"
            },
            {
                textData: 'Sport',
                imgSrc: require('../../assets/images/Sport.png'),
                bgChange: schema.Sport,
                screen: "Action"
            },
            {
                textData: 'Survival',
                imgSrc: require('../../assets/images/Shooting.png'),
                bgChange: schema.Survival,
                screen: "Action"
            },
            {
                textData: 'Number',
                imgSrc: require('../../assets/images/Number.png'),
                bgChange: schema.Number,
                screen: "Action"
            },
        ]
    }
export const cd = (schema: any): any[] => {
    return [
        {
            id: 0,
            gameName: "Candy Absolute",
            gameSrc: require("../../assets/games-icon/1.webp"),
            bgColor: "#ff0000",
            title: "Candy Absolute",
            background: schema.adventure,
            color: schema.color,
            OnPlayerScreen: 'GamePlayerViewScreen',
            screen: "GameScreen",
            Url: "https://www.cdn1.p9games.com/candyabsolute/index.html",
            gameType: 'Top Games',
            cat:'action'
        },
        {
            id: 1,
            gameName: "Cosmo Scape",
            gameSrc: require("../../assets/games-icon/2.webp"),
            bgColor: "#04E255",
            Url: "https://www.cdn1.p9games.com/cosmoscape/index.html",
            screen: "GameScreen", 
            OnPlayerScreen: 'GamePlayerViewScreen',
            title: "Cosmo Scape",
            background: schema.puzzle,
            color: schema.color,
            gameType: 'Tranding Card',
            cat:'quiz'
       
        },
        {
            id: 2,
            gameName: "Drag'n Ball",
            gameSrc: require("../../assets/games-icon/3.webp"),
            bgColor: "#ff0000",
            Url: "https://www.cdn1.p9games.com/dragnball/index.html",
            screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
            title: "Drag'n Ball",
            background: schema.strategy,
            color: schema.color,
        },
        {
            id: 3,
            gameName: "Dunk Dash",
            gameSrc: require("../../assets/games-icon/4.webp"),
            bgColor: "#04E255",
            Url: "https://www.cdn1.p9games.com/dunkdash/index.html",
            screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
            title: "Dunk Dash",
            background: schema.board,
            color: schema.color,
        },
        {
            id: 4,
            gameName: "Earth Invasion",
            gameSrc: require("../../assets/games-icon/5.webp"),
            bgColor: "#ff0000",
            Url: "https://www.cdn1.p9games.com/earthinvasion/index.html",
            screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
            title: "Earth Invasion",
            background: schema.action,
            color: schema.color,
        },
        {
            id: 5,
            gameName: "Face Busting Frenzy",
            gameSrc: require("../../assets/games-icon/6.webp"),
            bgColor: "#ff0000",
            screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
            title: "Face Busting Frenzy",
            background: schema.adventure,
            color: schema.color,
            Url: "https://www.cdn1.p9games.com/facebustinfrenzy/index.html",
        },
        {
            id: 6,
            gameName: "Fall or Build",
            gameSrc: require("../../assets/games-icon/7.webp"),
            bgColor: "#04E255",
            Url: "https://www.cdn1.p9games.com/fallorbuild/index.html",
            screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
            title: "Fall or Build",
            background: schema.puzzle,
            color: schema.color,
        },
        {
            id: 7,
            gameName: "Feed the pup",
            gameSrc: require("../../assets/games-icon/8.webp"),
            bgColor: "#ff0000",
            Url: "https://www.cdn1.p9games.com/feedthepup/index.html",
            screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
            title: "Feed the pup",
            background: schema.strategy,
            color: schema.color,
        },
        {
            id: 8,
            gameName: "Fist Fury",
            gameSrc: require("../../assets/games-icon/9.webp"),
            bgColor: "#04E255",
            Url: "https://www.cdn1.p9games.com/fistfury/index.html",
            screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
            title: "Fist Fury",
            background: schema.board,
            color: schema.color,
        },
        {
            id: 9,
            gameName: "Flaapy Fin",
            gameSrc: require("../../assets/games-icon/10.webp"),
            bgColor: "#ff0000",
            Url: "https://www.cdn1.p9games.com/flappyfins/index.html",
            screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
            title: "Flaapy Fin",
            background: schema.action,
            color: schema.color,
        },



        {
            id: 11,
            gameName: "Fruitopia Madness",
            gameSrc: require("../../assets/games-icon/11.webp"),
            bgColor: "#ff0000",
            screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
            title: "Fruitopia Madness",
            background: schema.adventure,
            color: schema.color,
            Url: "https://www.cdn1.p9games.com/fruitopiamadness/index.html",
        },
        {
            id: 12,
            gameName: "Happy Swap",
            gameSrc: require("../../assets/games-icon/12.webp"),
            bgColor: "#04E255",
            Url: "https://www.cdn1.p9games.com/happyswap/index.html",
            screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
            title: "Happy Swap",
            background: schema.puzzle,
            color: schema.color,
        },
        {
            id: 13,
            gameName: "Hole Hustle",
            gameSrc: require("../../assets/games-icon/13.webp"),
            bgColor: "#ff0000",
            Url: "https://www.cdn1.p9games.com/holehustle/index.html",
            screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
            title: "Hole Hustle",
            background: schema.strategy,
            color: schema.color,
        },
        {
            id: 14,
            gameName: "Hooked On Fish",
            gameSrc: require("../../assets/games-icon/14.webp"),
            bgColor: "#04E255",
            Url: "https://www.cdn1.p9games.com/hookedonfish/index.html",
            screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
            title: "Hooked On Fish",
            background: schema.board,
            color: schema.color,
        },
        {
            id: 15,
            gameName: "Hungry Rattle",
            gameSrc: require("../../assets/games-icon/15.webp"),
            bgColor: "#ff0000",
            Url: "https://www.cdn1.p9games.com/hungryrattle/index.html",
            screen: "GameScreen", 
            OnPlayerScreen: 'GamePlayerViewScreen',
            title: "Hungry Rattle",
            background: schema.action,
            color: schema.color,
        },
        // {
        //     id: 16,
        //     gameName: "Laser Escape",
        //     gameSrc: require("../../assets/games-icon/16.webp"),
        //     bgColor: "#ff0000",
        //     screen: "GameScreen", 
        //     OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Laser Escape",
        //     background: schema.adventure,
        //     color: schema.color,
        //     Url: "https://www.cdn1.p9games.com/Laserescape/index.html",
        // },
        // {
        //     id: 17,
        //     gameName: "Maths Riddle Master",
        //     gameSrc: require("../../assets/games-icon/17.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/mathsriddlemaster/index.html",
        //     screen: "GameScreen", 
        //     OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Maths Riddle Master",
        //     background: schema.puzzle,
        //     color: schema.color,
        // },
        // {
        //     id: 18,
        //     gameName: "Owl Flight",
        //     gameSrc: require("../../assets/games-icon/18.webp"),
        //     bgColor: "#ff0000",
        //     Url: "https://www.cdn1.p9games.com/owlflight/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Owl Flight",
        //     background: schema.strategy,
        //     color: schema.color,
        // },
        // {
        //     id: 19,
        //     gameName: "Pool Prodigy",
        //     gameSrc: require("../../assets/games-icon/19.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/poolprodigy/index.html",
        //     screen: "GameScreen", 
        //     OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Pool Prodigy",
        //     background: schema.board,
        //     color: schema.color,
        // },
        // {
        //     id: 20,
        //     gameName: "Pop Pop Ballons",
        //     gameSrc: require("../../assets/games-icon/20.webp"),
        //     bgColor: "#ff0000",
        //     Url: "https://www.cdn1.p9games.com/poppopballons/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Pop Pop Ballons",
        //     background: schema.action,
        //     color: schema.color,
        // },
        // {
        //     id: 21,
        //     gameName: "Space Warrior",
        //     gameSrc: require("../../assets/games-icon/21.webp"),
        //     bgColor: "#ff0000",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Space Warrior",
        //     background: schema.adventure,
        //     color: schema.color,
        //     Url: "https://www.cdn1.p9games.com/spacewarrior/index.html",
        // },
        // {
        //     id: 22,
        //     gameName: "Speed Ranger",
        //     gameSrc: require("../../assets/games-icon/22.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/speedranger/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Speed Ranger",
        //     background: schema.puzzle,
        //     color: schema.color,
        // },
        // {
        //     id: 23,
        //     gameName: "Tasty Harvest",
        //     gameSrc: require("../../assets/games-icon/23.webp"),
        //     bgColor: "#ff0000",
        //     Url: "https://www.cdn1.p9games.com/testyhavest/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Tasty Harvest",
        //     background: schema.strategy,
        //     color: schema.color,
        // },
        // {
        //     id: 24,
        //     gameName: "Minus Muncher",
        //     gameSrc: require("../../assets/games-icon/24.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/minusmuncher/index.html  ",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Minus Muncher",
        //     background: schema.board,
        //     color: schema.color,
        // },
        // {
        //     id: 25,
        //     gameName: "Sky Fisher",
        //     gameSrc: require("../../assets/games-icon/25.webp"),
        //     bgColor: "#ff0000",
        //     Url: "https://www.cdn1.p9games.com/skyfisher/index.html",
        //     screen: "GameScreen", 
        //     OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Sky Fisher",
        //     background: schema.action,
        //     color: schema.color,
        // },
        // {
        //     id: 26,
        //     gameName: "Ball Bounce Bash",
        //     gameSrc: require("../../assets/games-icon/26.webp"),
        //     bgColor: "#ff0000",
        //     screen: "GameScreen", 
        //     OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Ball Bounce Bash",
        //     background: schema.adventure,
        //     color: schema.color,
        //     Url: "https://www.cdn1.p9games.com/ballbouncebash/index.html",
        // },
        // {
        //     id: 27,
        //     gameName: "Air ball run",
        //     gameSrc: require("../../assets/games-icon/27.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/airballrun/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Air ball run",
        //     background: schema.puzzle,
        //     color: schema.color,
        // },
        // {
        //     id: 28,
        //     gameName: "Numerical Nimbas",
        //     gameSrc: require("../../assets/games-icon/28.webp"),
        //     bgColor: "#ff0000",
        //     Url: "https://www.cdn1.p9games.com/numericalnimbus/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Numerical Nimbas",
        //     background: schema.strategy,
        //     color: schema.color,
        // },
        // {
        //     id: 29,
        //     gameName: "Cosmic Confinement",
        //     gameSrc: require("../../assets/games-icon/29.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/cosmicconfinement/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Cosmic Confinement",
        //     background: schema.board,
        //     color: schema.color,
        // },
        // {
        //     id: 30,
        //     gameName: "Sting Shooter",
        //     gameSrc: require("../../assets/games-icon/30.webp"),
        //     bgColor: "#ff0000",
        //     Url: "https://www.cdn1.p9games.com/stingshooter/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Sting Shooter",
        //     background: schema.action,
        //     color: schema.color,
        // },



        // {
        //     id: 31,
        //     gameName: "Fruit Hive Shooter",
        //     gameSrc: require("../../assets/games-icon/31.webp"),
        //     bgColor: "#ff0000",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Fruit Hive Shooter",
        //     background: schema.adventure,
        //     color: schema.color,
        //     Url: "https://www.cdn1.p9games.com/fruithiveshooter/index.html",
        // },
        // {
        //     id: 32,
        //     gameName: "Squash Slash",
        //     gameSrc: require("../../assets/games-icon/32.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/squashslash/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Squash Slash",
        //     background: schema.puzzle,
        //     color: schema.color,
        // },
        // { imege not uploaded 
        //     id: 33,
        //     gameName: "Forest Brother",
        //     gameSrc: require("../../assets/games-icon/Hol Hustle.webp"),
        //     bgColor: "#ff0000",
        //     Url: "https://www.cdn1.p9games.com/forestbrothers/index.html",
        //     screen: "GameScreen",
        //     OnPlayerScreen:'GamePlayerViewScreen',
        //     title: "Forest Brother",
        //     background: schema.strategy,
        //     color: schema.color,
        // },
        // {
        //     id: 34,
        //     gameName: "Easter Memory",
        //     gameSrc: require("../../assets/games-icon/34.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/eastermemory/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Easter Memory",
        //     background: schema.board,
        //     color: schema.color,
        // },
        // {
        //     id: 35,
        //     gameName: "Pops Biliard ",
        //     gameSrc: require("../../assets/games-icon/35.webp"),
        //     bgColor: "#ff0000",
        //     Url: "https://www.cdn1.p9games.com/popsbilliards/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Pops Biliard ",
        //     background: schema.action,
        //     color: schema.color,
        // },
        // {
        //     id: 36,
        //     gameName: "Bubble Shooter",
        //     gameSrc: require("../../assets/games-icon/36.webp"),
        //     bgColor: "#ff0000",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Bubble Shooter",
        //     background: schema.adventure,
        //     color: schema.color,
        //     Url: "https://www.cdn1.p9games.com/bubbleshooter/index.html",
        // },
        // { image panding
        //     id: 37,
        //     gameName: "Scary Run",
        //     gameSrc: require("../../assets/games-icon/Maths Riddle Master.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/scaryrun/index.html",
        //     screen: "GameScreen",OnPlayerScreen:'GamePlayerViewScreen',
        //     title: "Scary Run",
        //     background: schema.puzzle,
        //     color: schema.color,
        // },
        // {
        //     id: 38,
        //     gameName: "Halloween Match-3",
        //     gameSrc: require("../../assets/games-icon/38.webp"),
        //     bgColor: "#ff0000",
        //     Url: "https://www.cdn1.p9games.com/halloweenmatch/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Halloween Match-3",
        //     background: schema.strategy,
        //     color: schema.color,
        // },
        // {
        //     id: 39,
        //     gameName: "Cars",
        //     gameSrc: require("../../assets/games-icon/Cars_icon.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/cars/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Cars",
        //     background: schema.board,
        //     color: schema.color,
        // },
        // {
        //     id: 40,
        //     gameName: "Animals crush Match-3",
        //     gameSrc: require("../../assets/games-icon/40.webp"),
        //     bgColor: "#ff0000",
        //     Url: "https://www.cdn1.p9games.com/animalscrushmatch/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Animals crush Match-3",
        //     background: schema.action,
        //     color: schema.color,
        // },
        // {
        //     id: 41,
        //     gameName: "Bashorun",
        //     gameSrc: require("../../assets/games-icon/41.webp"),
        //     bgColor: "#ff0000",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Bashorun",
        //     background: schema.adventure,
        //     color: schema.color,
        //     Url: "https://www.cdn1.p9games.com/bashorun/index.html",
        // },
        // {
        //     id: 42,
        //     gameName: "Fashion Dress Up",
        //     gameSrc: require("../../assets/games-icon/42.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/fashiondressup/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Fashion Dress Up",
        //     background: schema.puzzle,
        //     color: schema.color,
        // },
        // {
        //     id: 43,
        //     gameName: "Flappy Bounce",
        //     gameSrc: require("../../assets/games-icon/43.webp"),
        //     bgColor: "#ff0000",
        //     Url: "https://www.cdn1.p9games.com/flappybounce/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Flappy Bounce",
        //     background: schema.strategy,
        //     color: schema.color,
        // },
        // {
        //     id: 44,
        //     gameName: "Flappy Ball",
        //     gameSrc: require("../../assets/games-icon/44.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/flappyball/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Flappy Ball",
        //     background: schema.board,
        //     color: schema.color,
        // },
        // {
        //     id: 45,
        //     gameName: "Cartoon Candy ",
        //     gameSrc: require("../../assets/games-icon/45.webp"),
        //     bgColor: "#ff0000",
        //     Url: "https://www.cdn1.p9games.com/cartooncandy/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Cartoon Candy ",
        //     background: schema.action,
        //     color: schema.color,
        // },
        // {
        //     id: 46,
        //     gameName: "Memory Game",
        //     gameSrc: require("../../assets/games-icon/46.webp"),
        //     bgColor: "#ff0000",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Memory Game",
        //     background: schema.adventure,
        //     color: schema.color,
        //     Url: "https://www.cdn1.p9games.com/memorygame/index.html",
        // },
        // {
        //     id: 47,
        //     gameName: "Schitalochka",
        //     gameSrc: require("../../assets/games-icon/47.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/schitalochka/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Schitalochka",
        //     background: schema.puzzle,
        //     color: schema.color,
        // },
        // {
        //     id: 48,
        //     gameName: "Supercars Puzzle",
        //     gameSrc: require("../../assets/games-icon/48.webp"),
        //     bgColor: "#ff0000",
        //     Url: "https://www.cdn1.p9games.com/supercarspuzzle/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Supercars Puzzle",
        //     background: schema.strategy,
        //     color: schema.color,
        // },
        // { Image panding
        //     id: 49,
        //     gameName: "MGFK",
        //     gameSrc: require("../../assets/games-icon/Fist Fury.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/mgfk/index.html",
        //     screen: "GameScreen",OnPlayerScreen:'GamePlayerViewScreen',
        //     title: "MGFK",
        //     background: schema.board,
        //     color: schema.color,
        // },

        // {
        //     id: 50,
        //     gameName: "Zombie Uprising",
        //     gameSrc: require("../../assets/games-icon/50.webp"),
        //     bgColor: "#ff0000",
        //     Url: "https://www.cdn1.p9games.com/zombieuprising/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Zombie Uprising",
        //     background: schema.action,
        //     color: schema.color,
        // },



        // { Image panding
        //     id:51,
        //     gameName: "BTR80",
        //     gameSrc: require("../../assets/games-icon/Fruitopia Madness.webp"),
        //     bgColor: "#ff0000",
        //     screen: "GameScreen",OnPlayerScreen:'GamePlayerViewScreen',
        //     title: "BTR80",
        //     background: schema.adventure,
        //     color: schema.color,
        //     Url: "https://www.cdn1.p9games.com/btr80/index.html",
        // },

        // {
        //     id: 52,
        //     gameName: "Superpon Goal",
        //     gameSrc: require("../../assets/games-icon/Happy Swap.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/btr80/index.html",
        //     screen: "GameScreen",OnPlayerScreen:'GamePlayerViewScreen',
        //     title: "Superpon Goal",
        //     background: schema.puzzle,
        //     color: schema.color,
        // },

        // {
        //     id: 53,
        //     gameName: "Smiles",
        //     gameSrc: require("../../assets/games-icon/53.webp"),
        //     bgColor: "#ff0000",
        //     Url: "https://www.cdn1.p9games.com/smiles/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Smiles",
        //     background: schema.strategy,
        //     color: schema.color,
        // },
        // {
        //     id: 54,
        //     gameName: "Blast Of Ball",
        //     gameSrc: require("../../assets/games-icon/54.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/blastoffball/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Blast Of Ball",
        //     background: schema.board,
        //     color: schema.color,
        // },
        // {
        //     id: 55,
        //     gameName: "Charm pop",
        //     gameSrc: require("../../assets/games-icon/55.webp"),
        //     bgColor: "#ff0000",
        //     Url: "https://www.cdn1.p9games.com/charmpop/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Charm pop",
        //     background: schema.action,
        //     color: schema.color,
        // },
        // {
        //     id: 56,
        //     gameName: "NarrowPassage",
        //     gameSrc: require("../../assets/games-icon/56.webp"),
        //     bgColor: "#ff0000",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "NarrowPassage",
        //     background: schema.adventure,
        //     color: schema.color,
        //     Url: "https://www.cdn1.p9games.com/narrowpassage/index.html",
        // },
        // {
        //     id: 57,
        //     gameName: "Pure h20",
        //     gameSrc: require("../../assets/games-icon/57.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/pureh20/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Pure h20",
        //     background: schema.puzzle,
        //     color: schema.color,
        // },
        // {
        //     id: 58,
        //     gameName: "Jet dual",
        //     gameSrc: require("../../assets/games-icon/58.webp"),
        //     bgColor: "#ff0000",
        //     Url: "https://www.cdn1.p9games.com/jetdual/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Jet Dual",
        //     background: schema.strategy,
        //     color: schema.color,
        // },
        // {
        //     id: 59,
        //     gameName: "Hot Jewels",
        //     gameSrc: require("../../assets/games-icon/59.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/hotjewels/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Pool Prodigy",
        //     background: schema.board,
        //     color: schema.color,
        // },
        // { image panding
        //     id: 60,
        //     gameName: "Professor bubble",
        //     gameSrc: require("../../assets/games-icon/poppop baloon.webp"),
        //     bgColor: "#ff0000",
        //     Url: "https://www.cdn1.p9games.com/professorbubble/index.html",
        //     screen: "GameScreen",OnPlayerScreen:'GamePlayerViewScreen',
        //     title: "Professor Bubble",
        //     background: schema.action,
        //     color: schema.color,
        // },
        // {
        //     id: 61,
        //     gameName: "Ninja Run",
        //     gameSrc: require("../../assets/games-icon/61.webp"),
        //     bgColor: "#ff0000",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Ninja Run",
        //     background: schema.adventure,
        //     color: schema.color,
        //     Url: "https://www.cdn1.p9games.com/ninjarun/index.html",
        // },
        // { image panding
        //     id: 62,
        //     gameName: "Quick Dice",
        //     gameSrc: require("../../assets/games-icon/Speed Ranger.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/quickdice/index.html",
        //     screen: "GameScreen",OnPlayerScreen:'GamePlayerViewScreen',
        //     title: "Quick Dice",
        //     background: schema.puzzle,
        //     color: schema.color,
        // },
        // {
        //     id: 63,
        //     gameName: "Mad Shark",
        //     gameSrc: require("../../assets/games-icon/Tasty Harvest.webp"),
        //     bgColor: "#ff0000",
        //     Url: "https://www.cdn1.p9games.com/madshark/index.html",
        //     screen: "GameScreen",OnPlayerScreen:'GamePlayerViewScreen',
        //     title: "Mad Shark",
        //     background: schema.strategy,
        //     color: schema.color,
        // },
        // {
        //     id: 64,
        //     gameName: "Stick Soldier",
        //     gameSrc: require("../../assets/games-icon/64.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/sticksoldier/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Stick Soldier",
        //     background: schema.card,
        //     color: schema.color,
        // },
        // {
        //     id: 65,
        //     gameName: "Kids True Colour",
        //     gameSrc: require("../../assets/games-icon/65.webp"),
        //     bgColor: "#ff0000",
        //     Url: "https://www.cdn1.p9games.com/kidstruecolor/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Kids True Colour",
        //     background: schema.card,
        //     color: schema.color,
        // },
        // {
        //     id: 66,
        //     gameName: "Great Air Battle",
        //     gameSrc: require("../../assets/games-icon/Ball Bounce Bash.webp"),
        //     bgColor: "#ff0000",
        //     screen: "GameScreen",OnPlayerScreen:'GamePlayerViewScreen',
        //     title: "Great Air Battle",
        //     background: schema.adventure,
        //     color: schema.color,
        //     Url: "https://www.cdn1.p9games.com/greatairbattles/index.html",
        // },
        // {
        //     id: 67,
        //     gameName: "Christas Match",
        //     gameSrc: require("../../assets/games-icon/67.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/christmasmatch/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Christas Match",
        //     background: schema.puzzle,
        //     color: schema.color,
        // },
        // {
        //     id: 68,
        //     gameName: "Christmas Ballons",
        //     gameSrc: require("../../assets/games-icon/Numerical Nimbus.webp"),
        //     bgColor: "#ff0000",
        //     Url: "https://www.cdn1.p9games.com/christmasballoons/index.html",
        //     screen: "GameScreen",OnPlayerScreen:'GamePlayerViewScreen',
        //     title: "Christmas Ballons",
        //     background: schema.strategy,
        //     color: schema.color,
        // },
        // {
        //     id: 69,
        //     gameName: "Christmas Panda Run",
        //     gameSrc: require("../../assets/games-icon/69.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/christmaspandarun/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Christmas Panda Run",
        //     background: schema.board,
        //     color: schema.color,
        // },
        // {
        //     id: 70,
        //     gameName: "Speed Racer",
        //     gameSrc: require("../../assets/games-icon/70.webp"),
        //     bgColor: "#ff0000",
        //     Url: "https://www.cdn1.p9games.com/speedracer/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Speed Racer",
        //     background: schema.action,
        //     color: schema.color,
        // },



        // {
        //     id: 71,
        //     gameName: "Zombie Shooter",
        //     gameSrc: require("../../assets/games-icon/71.webp"),
        //     bgColor: "#ff0000",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Zombie Shooter",
        //     background: schema.adventure,
        //     color: schema.color,
        //     Url: "https://www.cdn1.p9games.com/zombieshooter/index.html",
        // },
        // {
        //     id: 72,
        //     gameName: "Traffic ",
        //     gameSrc: require("../../assets/games-icon/72.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/traffic/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Traffic",
        //     background: schema.puzzle,
        //     color: schema.color,
        // },
        // {
        //     id: 73,
        //     gameName: "Girl Dress Up",
        //     gameSrc: require("../../assets/games-icon/73.webp"),
        //     bgColor: "#ff0000",
        //     Url: "https://www.cdn1.p9games.com/girldressup/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Girl Dress Up",
        //     background: schema.strategy,
        //     color: schema.color,
        // },
        // {
        //     id: 74,
        //     gameName: "Shoot Robers",
        //     gameSrc: require("../../assets/games-icon/74.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/shootrobbers/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Shoot Robers",
        //     background: schema.board,
        //     color: schema.color,
        // },
        // { image panding 
        //     id: 75,
        //     gameName: "Duck Shooter",
        //     gameSrc: require("../../assets/games-icon/Duck Hunter.webp"),
        //     bgColor: "#ff0000",
        //     Url: "https://www.cdn1.p9games.com/duckshooter/index.html",
        //     screen: "GameScreen",OnPlayerScreen:'GamePlayerViewScreen',
        //     title: "Duck Shooter",
        //     background: schema.action,
        //     color: schema.color,
        // },
        // {
        //     id: 76,
        //     gameName: "Super Cowboy Run",
        //     gameSrc: require("../../assets/games-icon/76.webp"),
        //     bgColor: "#ff0000",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Super Cowboy Run",
        //     background: schema.adventure,
        //     color: schema.color,
        //     Url: "https://www.cdn1.p9games.com/supercowboyrun/index.html",
        // },
        // {image Only
        //     id: 77,
        //     gameName: "Space Purge",
        //     gameSrc: require("../../assets/games-icon/super cow boy run.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/duckshooter/index.html",
        //     screen: "GameScreen",OnPlayerScreen:'GamePlayerViewScreen',
        //     title: "Space Purge",
        //     background: schema.puzzle,
        //     color: schema.color,
        // },
        // {
        //     id: 78,
        //     gameName: "Crazy Runner",
        //     gameSrc: require("../../assets/games-icon/78.webp"),
        //     bgColor: "#ff0000",
        //     Url: "https://www.cdn1.p9games.com/crazyrunner/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Crazy Runner",
        //     background: schema.strategy,
        //     color: schema.color,
        // },
        
        // { image panding
        //     id: 79,
        //     gameName: "Tank Defender ",
        //     gameSrc: require("../../assets/games-icon/Cars_icon.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/tankdefender/index.html",
        //     screen: "GameScreen",OnPlayerScreen:'GamePlayerViewScreen',
        //     title: "Tank Defender ",
        //     background: schema.board,
        //     color: schema.color,
        // },

        // { image panding 
        //     id: 80,
        //     gameName: "Fishing Frenzy",
        //     gameSrc: require("../../assets/games-icon/Animal Crush.webp"),
        //     bgColor: "#ff0000",
        //     Url: "https://www.cdn1.p9games.com/fishingfrenzy/index.html",
        //     screen: "GameScreen",OnPlayerScreen:'GamePlayerViewScreen',
        //     title: "Fishing Frenzy",
        //     background: schema.action,
        //     color: schema.color,
        // },
        // {
        //     id: 81,
        //     gameName: "Casino - Card Memory",
        //     gameSrc: require("../../assets/games-icon/81.webp"),
        //     bgColor: "#ff0000",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Casino - Card Memory",
        //     background: schema.adventure,
        //     color: schema.color,
        //     Url: "https://www.cdn1.p9games.com/casinocardsmemory/index.html",
        // },
        // {
        //     id: 82,
        //     gameName: "Fruit Snake",
        //     gameSrc: require("../../assets/games-icon/Fruit Slasher.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/fruitsnake/index.html",
        //     screen: "GameScreen",OnPlayerScreen:'GamePlayerViewScreen',
        //     title: "Fruit Snake",
        //     background: schema.puzzle,
        //     color: schema.color,
        // },
        // {
        //     id: 83,
        //     gameName: "Halloween Bubble Shooter",
        //     gameSrc: require("../../assets/games-icon/Dragon Ball.webp"),
        //     bgColor: "#ff0000",
        //     Url: "https://www.cdn1.p9games.com/halloweenbubbleshooter/index.html",
        //     screen: "GameScreen",OnPlayerScreen:'GamePlayerViewScreen',
        //     title: "Halloween Bubble Shooter",
        //     background: schema.strategy,
        //     color: schema.color,
        // },
        // {
        //     id: 84,
        //     gameName: "Ninja Run New",
        //     gameSrc: require("../../assets/games-icon/Ninja Run.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/ninjarunnew/index.html",
        //     screen: "GameScreen",OnPlayerScreen:'GamePlayerViewScreen',
        //     title: "Ninja Run New",
        //     background: schema.board,
        //     color: schema.color,
        // },
        // {
        //     id: 85,
        //     gameName: "Brick Out",
        //     gameSrc: require("../../assets/games-icon/Earth Invasion.webp"),
        //     bgColor: "#ff0000",
        //     Url: "https://www.cdn1.p9games.com/ninjarunnew/index.html",
        //     screen: "GameScreen",OnPlayerScreen:'GamePlayerViewScreen',
        //     title: "Brick Out",
        //     background: schema.action,
        //     color: schema.color,
        // },
        // {
        //     id: 86,
        //     gameName: "Ranger Vs Zombie",
        //     gameSrc: require("../../assets/games-icon/86.webp"),
        //     bgColor: "#ff0000",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Ranger Vs Zombie",
        //     background: schema.adventure,
        //     color: schema.color,
        //     Url: "https://www.cdn1.p9games.com/rangervszombies/index.html",
        // },
        // {
        //     id: 87,
        //     gameName: "Duck Hunter",
        //     gameSrc: require("../../assets/games-icon/87.webp"),
        //     bgColor: "#04E255",
        //     Url: "http://games.unanimoustech.com/duckhunter/",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Duck Hunter",
        //     background: schema.puzzle,
        //     color: schema.color,
        // },
        // {
        //     id: 88,
        //     gameName: "Viking Escape",
        //     gameSrc: require("../../assets/games-icon/88.webp"),
        //     bgColor: "#ff0000",
        //     Url: "https://www.cdn1.p9games.com/vikingescape/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Viking Escape",
        //     background: schema.strategy,
        //     color: schema.color,
        // },
        // {
        //     id: 89,
        //     gameName: "Gold Miner",
        //     gameSrc: require("../../assets/games-icon/89.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/goldminer/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Gold Miner",
        //     background: schema.board,
        //     color: schema.color,
        // },
        // {
        //     id: 90,
        //     gameName: "Plumber",
        //     gameSrc: require("../../assets/games-icon/90.webp"),
        //     bgColor: "#ff0000",
        //     Url: "https://www.cdn1.p9games.com/plumber/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Plumber",
        //     background: schema.action,
        //     color: schema.color,
        // },



        // {
        //     id: 91,
        //     gameName: "Path Pacer",
        //     gameSrc: require("../../assets/games-icon/91.webp"),
        //     bgColor: "#ff0000",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Path Pacer",
        //     background: schema.adventure,
        //     color: schema.color,
        //     Url: "https://www.cdn1.p9games.com/pathpacer/index.html",
        // },


        // { Game On AWS SERVER LINK
        //     id: 92,
        //     gameName: "Hue Hit",
        //     gameSrc: require("../../assets/games-icon/92.webp"),
        //     bgColor: "#04E255",
        //     Url: "",
        //     screen: "GameScreen", 
        //     OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Hue Hit",
        //     background: schema.puzzle,
        //     color: schema.color,
        // },

        // {
        //     id: 93,
        //     gameName: "Zombie Buster",
        //     gameSrc: require("../../assets/games-icon/Hol Hustle.webp"),
        //     bgColor: "#ff0000",
        //     Url: "https://www.cdn1.p9games.com/zombiebuster/index.html",
        //     screen: "GameScreen",OnPlayerScreen:'GamePlayerViewScreen',
        //     title: "Zombie Buster",
        //     background: schema.strategy,
        //     color: schema.color,
        // },

        // {
        //     id: 94,
        //     gameName: "Stick Monkey",
        //     gameSrc: require("../../assets/games-icon/94.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/stickmonkey/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Stick Monkey",
        //     background: schema.board,
        //     color: schema.color,
        // },
        // {
        //     id: 95,
        //     gameName: "Mummy Candies ",
        //     gameSrc: require("../../assets/games-icon/95.webp"),
        //     bgColor: "#ff0000",
        //     Url: "https://www.cdn1.p9games.com/halloweenmemory/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Mummy Candies",
        //     background: schema.action,
        //     color: schema.color,
        // },
        // { Image Panding
        //     id: 96,
        //     gameName: "Halloween Memory",
        //     gameSrc: require("../../assets/games-icon/Laser escape.webp"),
        //     bgColor: "#ff0000",
        //     screen: "GameScreen",OnPlayerScreen:'GamePlayerViewScreen',
        //     title: "Halloween Memory",
        //     background: schema.adventure,
        //     color: schema.color,
        //     Url: "https://www.cdn1.p9games.com/halloweenmemory/index.html",
        // },
        // {
        //     id: 97,
        //     gameName: "Angry Cat Shot",
        //     gameSrc: require("../../assets/games-icon/Maths Riddle Master.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/angrycatshot/index.html",
        //     screen: "GameScreen",OnPlayerScreen:'GamePlayerViewScreen',
        //     title: "Angry Cat Shot",
        //     background: schema.puzzle,
        //     color: schema.color,
        // },
        // {
        //     id: 98,
        //     gameName: "Fruit Slasher",
        //     gameSrc: require("../../assets/games-icon/98.webp"),
        //     bgColor: "#ff0000",
        //     Url: "https://www.cdn1.p9games.com/fruitslasher/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Fruit Slasher",
        //     background: schema.strategy,
        //     color: schema.color,
        // },
        // {
        //     id: 99,
        //     gameName: "Swat Vs Zombies",
        //     gameSrc: require("../../assets/games-icon/99.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/swatvszombies/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Swat Vs Zombies",
        //     background: schema.board,
        //     color: schema.color,
        // },

        // {
        //     id: 100,
        //     gameName: "Tank Wars",
        //     gameSrc: require("../../assets/games-icon/100.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/tankwars/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Tank Wars",
        //     background: schema.adventure,
        //     color: schema.color,
        // },
        // {
        //     id: 101,
        //     gameName: "Air Warfare",
        //     gameSrc: require("../../assets/games-icon/101.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/airwarfare/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Air Warfare",
        //     background: schema.puzzle,
        //     color: schema.color,
        // },
        // {
        //     id: 102,
        //     gameName: "Mad Scientist",
        //     gameSrc: require("../../assets/games-icon/102.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/madscientist/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Mad Scientist",
        //     background: schema.strategy,
        //     color: schema.color,
        // },
        // {
        //     id: 103,
        //     gameName: "Stack Jump",
        //     gameSrc: require("../../assets/games-icon/103.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/stackjump/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Stack Jump",
        //     background: schema.board,
        //     color: schema.color,
        // },
        // {
        //     id: 104,
        //     gameName: "Happy Halloween",
        //     gameSrc: require("../../assets/games-icon/104.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/happyhalloween/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Happy Halloween",
        //     background: schema.action,
        //     color: schema.color,
        // },
        // {
        //     id: 105,
        //     gameName: "Santa Run",
        //     gameSrc: require("../../assets/games-icon/105.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/santarun/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Santa Run",
        //     background: schema.adventure,
        //     color: schema.color,
        // },
        // {
        //     id: 106,
        //     gameName: "Handless Milionaire",
        //     gameSrc: require("../../assets/games-icon/106.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/handlessmillionaire/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Handless Milionaire",
        //     background: schema.puzzle,
        //     color: schema.color,
        // },
        // {
        //     id: 107,
        //     gameName: "Gold Miner Jack",
        //     gameSrc: require("../../assets/games-icon/107.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/goldminerjack/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Gold Miner Jack",
        //     background: schema.strategy,
        //     color: schema.color,
        // },
        // {
        //     id: 108,
        //     gameName: "Traffic Command",
        //     gameSrc: require("../../assets/games-icon/108.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/trafficcommand/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Traffic Command",
        //     background: schema.board,
        //     color: schema.color,
        // },
        // {
        //     id: 109,
        //     gameName: "Mine Hunt",
        //     gameSrc: require("../../assets/games-icon/109.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/minehunt/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Mine Hunt",
        //     background: schema.action,
        //     color: schema.color,
        // },
        // {
        //     id: 110,
        //     gameName: "Airborne Warrior",
        //     gameSrc: require("../../assets/games-icon/110.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/airbornewarrior/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Airborne Warrior",
        //     background: schema.adventure,
        //     color: schema.color,
        // },
        // {
        //     id: 111,
        //     gameName: "Soccer Slam",
        //     gameSrc: require("../../assets/games-icon/111.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/soccerslam/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Soccer Slam",
        //     background: schema.puzzle,
        //     color: schema.color,
        // },

        // {
        //     id: 112,
        //     gameName: "Sky Soarer",
        //     gameSrc: require("../../assets/games-icon/112.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/skysoarer/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Sky Soarer",
        //     background: schema.strategy,
        //     color: schema.color,
        // },
        // {
        //     id: 113,
        //     gameName: "Panda Storm",
        //     gameSrc: require("../../assets/games-icon/113.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/pandastorm/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Panda Storm",
        //     background: schema.board,
        //     color: schema.color,
        // },
        // {
        //     id: 114,
        //     gameName: "Soccer Slam",
        //     gameSrc: require("../../assets/games-icon/114.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/soccerslam/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Soccer Slam",
        //     background: schema.action,
        //     color: schema.color,
        // },
        // { Game on AWS Server
        //     id: 115,
        //     gameName: "Bumpy Road Ride",
        //     gameSrc: require("../../assets/games-icon/115.webp"),
        //     bgColor: "#04E255",
        //     Url: "",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Bumpy Road Ride",
        //     background: schema.adventure,
        //     color: schema.color,
        // },
        // { Game On Aws And Image
        //     id: 116,
        //     gameName: "King Solitaire",
        //     gameSrc: require("../../assets/games-icon/SwatVs Zombies.webp"),
        //     bgColor: "#04E255",
        //     Url: "",
        //     screen: "GameScreen",OnPlayerScreen:'GamePlayerViewScreen',
        //     title: "King Solitaire",
        //     background: schema.puzzle,
        //     color: schema.color,
        // },
        // { Game On Aws
        //     id: 117,
        //     gameName: "Ludo Combat",
        //     gameSrc: require("../../assets/games-icon/117.webp"),
        //     bgColor: "#04E255",
        //     Url: "",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Ludo Combat",
        //     background: schema.strategy,
        //     color: schema.color,
        // },
        // {
        //     id: 118,
        //     gameName: "Princess Sprint",
        //     gameSrc: require("../../assets/games-icon/118.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/princesssprint/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Princess Sprint",
        //     background: schema.board,
        //     color: schema.color,
        // },
        // {
        //     id: 119,
        //     gameName: "Snake & Ladder Safari",
        //     gameSrc: require("../../assets/games-icon/119.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/snake&ladderssafari/index.html",
        //     screen: "GameScreen",OnPlayerScreen:'GamePlayerViewScreen',
        //     title: "Snake & Ladder Safari",
        //     background: schema.action,
        //     color: schema.color,
        // },
        // {
        //     id: 120,
        //     gameName: "Sea Adventure",
        //     gameSrc: require("../../assets/games-icon/120.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/seaddventure/index.html",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Sea Adventure",
        //     background: schema.adventure,
        //     color: schema.color,
        // },
        // {
        //     id: 121,
        //     gameName: "ballon burst blits",
        //     gameSrc: require("../../assets/games-icon/121.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/baloonburstblitz/index.html",
        //     screen: "GameScreen",OnPlayerScreen:'GamePlayerViewScreen',
        //     title: "ballon burst blits",
        //     background: schema.puzzle,
        //     color: schema.color,
        // },
        // {
        //     id: 122,
        //     gameName: "kick off kings",
        //     gameSrc: require("../../assets/games-icon/122.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/kickoffkings/index.html",
        //     screen: "GameScreen",OnPlayerScreen:'GamePlayerViewScreen',
        //     title: "kick off kings",
        //     background: schema.strategy,
        //     color: schema.color,
        // },
        // { Game on Aws
        //     id: 123,
        //     gameName: "Traffic Tangle",
        //     gameSrc: require("../../assets/games-icon/123.webp"),
        //     bgColor: "#04E255",
        //     Url: "",
        //     screen: "GameScreen", OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Traffic Tangle",
        //     background: schema.board,
        //     color: schema.color,
        // },
        // { Image 
        //     id: 124,
        //     gameName: "Equation master",
        //     gameSrc: require("../../assets/games-icon/SwatVs Zombies.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/equationmaster/index.html",
        //     screen: "GameScreen",OnPlayerScreen:'GamePlayerViewScreen',
        //     title: "Equation master",
        //     background: schema.action,
        //     color: schema.color,
        // },
        // {
        //     id: 125,
        //     gameName: "Prism Paddle",
        //     gameSrc: require("../../assets/games-icon/SwatVs Zombies.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/prismpaddle/index.html",
        //     screen: "GameScreen",OnPlayerScreen:'GamePlayerViewScreen',
        //     title: "Prism Paddle",
        //     background: schema.adventure,
        //     color: schema.color,
        // },
        // {
        //     id: 126,
        //     gameName: "Furry Escape",
        //     gameSrc: require("../../assets/games-icon/126.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/furryescape/index.html",
        //     screen: "GameScreen", 
        //     OnPlayerScreen: 'GamePlayerViewScreen',
        //     title: "Furry Escape",
        //     background: schema.puzzle,
        //     color: schema.color,
        // },
        // { image
        //     id: 127,
        //     gameName: "Frosy hops",
        //     gameSrc: require("../../assets/games-icon/SwatVs Zombies.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/frosyhops/index.html",
        //     screen: "GameScreen",
        //     OnPlayerScreen:'GamePlayerViewScreen',
        //     title: "Frosy hops",
        //     background: schema.strategy,
        //     color: schema.color,
        // },
        // { Game Aws Server
        //     id: 128,
        //     gameName: "Astro Orbit",
        //     gameSrc: require("../../assets/games-icon/SwatVs Zombies.webp"),
        //     bgColor: "#04E255",
        //     Url: "",
        //     screen: "GameScreen",OnPlayerScreen:'GamePlayerViewScreen',
        //     title: "Astro Orbit",
        //     background: schema.board,
        //     color: schema.color,
        // },
        // {
        //     id: 129,
        //     gameName: "Aero Hockey ",
        //     gameSrc: require("../../assets/games-icon/129.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/aerohockey/index.html",
        //     screen: "GameScreen",OnPlayerScreen:'GamePlayerViewScreen',
        //     title: "Aero Hockey ",
        //     background: schema.action,
        //     color: schema.color,
        // },
        // {image
        //     id: 130,
        //     gameName: "Leap Hooper ",
        //     gameSrc: require("../../assets/games-icon/SwatVs Zombies.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/leaphooper/index.html",
        //     screen: "GameScreen",
        //     OnPlayerScreen:'GamePlayerViewScreen',
        //     title: "Leap Hooper ",
        //     background: schema.adventure,
        //     color: schema.color,
        // },
        // {
        //     id: 131,
        //     gameName: "Bird Stumble ",
        //     gameSrc: require("../../assets/games-icon/131.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/birdstumble/index.html",
        //     screen: "GameScreen",OnPlayerScreen:'GamePlayerViewScreen',
        //     title: "Bird Stumble ",
        //     background: schema.board,
        //     color: schema.color,
        // },
        // {
        //     id: 132,
        //     gameName: "Aero Hockey ",
        //     gameSrc: require("../../assets/games-icon/129.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/aerohockey/index.html",
        //     screen: "GameScreen",
            //    OnPlayerScreen:'GamePlayerViewScreen',
        //     title: "Aero Hockey ",
        //     background: schema.action,
        //     color: schema.color,
        // },
        // {
        //     id: 133,
        //     gameName: "Aero Hockey ",
        //     gameSrc: require("../../assets/games-icon/129.webp"),
        //     bgColor: "#04E255",
        //     Url: "https://www.cdn1.p9games.com/aerohockey/index.html",
        //     screen: "GameScreen",OnPlayerScreen:'GamePlayerViewScreen',
        //     title: "Aero Hockey ",
        //     background: schema.action,
        //     color: schema.color,
        // },
    ]
}


export const qc = (schema: any): any[] => {
    return [
        {

            id: 0,
            gameName: "Candy Absolute",
            gameSrc: require("../../assets/games-icon/1.webp"),
            screen: "GameScreen",
            title: "Candy Absolute",
            background: schema.adventure,
            OnPlayerScreen:'GamePlayerViewScreen',
            color: schema.color,
            Url: "https://www.cdn1.p9games.com/dunkdash/index.html",
        },
        {
            id: 1,
            gameName: "Cosmo Scape",
            gameSrc: require("../../assets/games-icon/2.webp"),
            background: schema.puzzle,
            screen: "GameScreen",
            Url: "https://www.cdn1.p9games.com/cosmoscape/index.html",
            OnPlayerScreen: "GamePlayerViewScreen",
            title: "Cosmo Scape",
            color: schema.color,
        },
        {
            id: 2,
            gameName: "Drag'n Ball",
            gameSrc: require("../../assets/games-icon/3.webp"),
            background: schema.strategy,
            Url: "https://www.cdn1.p9games.com/dragnball/index.html",
            screen: "GameScreen",
            title: "Drag'n Ball",
            color: schema.color,
            OnPlayerScreen:'GamePlayerViewScreen',
        },
        {
            id: 3,
            gameName: "Dunk Dash",
            gameSrc: require("../../assets/games-icon/4.webp"),
            background: schema.board,
            Url: "https://www.cdn1.p9games.com/dunkdash/index.html",
            screen: "GamePlayerViewScreen",
            title: "Dunk Dash",
            
            color: schema.color,
        },
        {
            id: 4,
            gameName: "Earth Invasion",
            gameSrc: require("../../assets/games-icon/5.webp"),
            background: schema.strategy,
            Url: "https://www.cdn1.p9games.com/earthinvasion/index.html",
            screen: "GamePlayerViewScreen",
            title: "Earth Invasion",
            bgChange: schema.action,
            color: schema.color,
        },
        {
            id: 5,
            gameName: "Face Busting Frenzy",
            gameSrc: require("../../assets/games-icon/6.webp"),
            background: schema.adventure,
            screen: "GamePlayerViewScreen",
            title: "Face Busting Frenzy",
            color: schema.color,
            Url: "https://www.cdn1.p9games.com/facebustinfrenzy/index.html",
        },

    ]
}