

import React, { FC, useContext, useRef, useState } from "react"
import { observer } from "mobx-react-lite"
import { View, StyleSheet, Text, Dimensions, ScrollView, useColorScheme } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { AppStackScreenProps } from "../navigators"
import themeContext from "../theme/themeContext"
import { useWindowDimensions } from "react-native"
// import { ActivityScreen } from "../components/ActivityScreen"
import { ActivityScreen } from "../components/ActivityUserCard"
import theme from "../theme/theme"
import Draggable from "react-draggable"
import { Appearance } from "react-native-appearance"
import { isMobile } from "react-device-detect"
import CountDownTimer from 'react-native-countdown-timer-hooks';
// REMOVE ME! ⬇️ This TS ignore will not be necessary after you've added the correct navigator param type
// @ts-ignore
export const SpendingTimeScreen: FC<StackScreenProps<AppStackScreenProps, "Sessions">> = observer(function SpendingTimeScreen() {
    // const theme = useContext(themeContext);  
    const styles = useStyles()
    const colorScheme = useColorScheme();
    const [responsiveDrag, setResponsiveDrag]: any = useState();
    const { width } = Dimensions.get('window');
    const schema = colorScheme === "dark" ? theme.dark : theme.light



    let dynamicWidth = (width * 100) / 100
    if (
        responsiveDrag === 8 || responsiveDrag > 8 && responsiveDrag < 15
    ) {
        dynamicWidth = (width * 60) / 100
    }
    else if (

        responsiveDrag === 15
    ) {
        dynamicWidth = (width * 75) / 100
        // console.log('widht')
    }
    else if (
        responsiveDrag === 20 || responsiveDrag > 15
    ) {
        dynamicWidth = (width * 35) / 100
        console.log('widht')
    }

    const scrollWidth = width - dynamicWidth;

    const handleDrag = (e, data) => {
        console.log(scrollWidth, "width")
        if (data.x < -scrollWidth) {
            data.x = -scrollWidth
        }
        if (data.x > (scrollWidth)) {
            data.x = scrollWidth
        }
    }

    const handleLayout = (e) => {

        setResponsiveDrag(GameSession.length)
    };

    // Timer


    const GameSession = [
        {
            id: 0,
            source: require("../../assets/images/Bird-Tumble.png"),
            PrizePool: "700",
            Players: "2000",
            BackGround: "#91B7D0",
            ActivityTime: 120,
        },

        {
            id: 1,
            source: require("../../assets/images/Bird-Tumble.png"),
            PrizePool: "700",
            Players: "2000",
            BackGround: "#42C977",
            ActivityTime: 130,
        },

    ]



    return (
        <View style={[styles.ActivityScreenMainContainer, { backgroundColor: schema.backgroundSecondary }]}>
            <View style={styles.SessionGameDisplayDataWidthResposive}>
                <ScrollView
                    horizontal={false}
                    onLayout={handleLayout}
                    showsVerticalScrollIndicator={false}
                    alwaysBounceVertical={true}

                >

                    {!isMobile ? (
                        <Draggable
                            axis="y"
                            onDrag={handleDrag}
                            bounds={{ top: -scrollWidth, bottom: 0 }}
                        >
                            <View >
                                {GameSession.map((GameSession, i) => (
                                    <ActivityScreen
                                        key={`${GameSession.id}-${i++}`}
                                        source={GameSession.source}
                                        PrizePool={GameSession.PrizePool}
                                        Players={GameSession.Players}
                                        BackGround={GameSession.BackGround}
                                        ActivityTime={GameSession.ActivityTime}
                                        {...GameSession}
                                        {...ActivityScreen}
                                    />

                                ))}
                            </View>
                        </Draggable>
                    ) : (
                        <View style={{ margin: "auto" }}>
                            {GameSession.map((GameSession, i) => (
                                <ActivityScreen
                                    key={`${GameSession.id}-${i++}`}
                                    source={GameSession.source}
                                    PrizePool={GameSession.PrizePool}
                                    Players={GameSession.Players}
                                    BackGround={GameSession.BackGround}
                                    ActivityTime={GameSession.ActivityTime}
                                    {...GameSession}
                                    {...ActivityScreen}
                                />
                            ))}
                        </View>
                    )}

                    <View>


                    </View>
                </ScrollView>

                {/* <Draggable
                axis="y"
                onDrag={handleDrag}
                bounds={{ top: -scrollWidth, bottom: 200 }}
            >
                {GameSession.map((GameSession, i) => (
                    <SissionScreenGames
                        key={`${GameSession.id}-${i++}`}
                        source={GameSession.source}
                        PrizePool={GameSession.PrizePool}
                        Players={GameSession.Players}
                        BackGround={GameSession.BackGround}
                        {...GameSession}
                        {...SissionScreenGames}
                    />
                ))}
            </Draggable> */}
            </View >
        </View >
    )
})

function useStyles() {
    const { width, height } = useWindowDimensions()
    return StyleSheet.create({
        SessionGameDisplayDataWidthResposive: {
            width: (width > 600) ? "30%" : "90%",
            flex: 1,
            margin: "auto",
        },
        ActivityScreenMainContainer: {
            width: "100%",
            flex: 1,
        },
    } as any)
}
