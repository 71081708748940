import React, { FC, useState } from "react"
import { observer } from "mobx-react-lite"
import {
  View,
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  useColorScheme,
  Switch,
  ImageBackground,
  TextInput,
  Picker,
  Modal,
  Button
} from "react-native";
import { StackScreenProps } from "@react-navigation/stack"
import { AppStackScreenProps } from "../navigators"
import { Screen, } from "../components"
import { useTheme } from "../constant/TheamContext"
import theme from "../theme/theme"
import { isMobile } from "react-device-detect";
import { Card } from "@rneui/base";
import { Input } from "@rneui/themed";
// import { useNavigation } from "@react-navigation/native"
// import { useStores } from "../models"

// STOP! READ ME FIRST!
// To fix the TS error below, you'll need to add the following things in your navigation config:
// - Add `Edit: undefined` to AppStackParamList
// - Import your screen, and add it to the stack:
//     `<Stack.Screen name="Edit" component={EditScreen} />`
// Hint: Look for the 🔥!

// REMOVE ME! ⬇️ This TS ignore will not be necessary after you've added the correct navigator param type
// @ts-ignore
export const EditScreen: FC<StackScreenProps<AppStackScreenProps, "Edit">> = observer(function EditScreen() {
  // Pull in one of our MST stores
  // const { someStore, anotherStore } = useStores()

  // Pull in navigation via hook
  // const navigation = useNavigation()

  const { themeColor } = useTheme();

  const schema = themeColor === "dark" ? theme.dark : theme.light;
  const [text, onChangeText] = useState();
  const [text1, onChange1Text] = useState();
  const [text2, onChange2Text] = useState();
  const [text3, onChange3Text] = useState();

  const [selectedValue, setSelectedValue] = useState("India ");


  // const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState("");
  const [about, setAbout] = useState("");

   const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [savedValues, setSavedValues] = useState({
    name: "",
    email: "",
    country: "India",
    location: "",
    about: "",
  });

  const handleSavePress = () => {
    const newValues = {
      name,
      email,
      country: selectedValue,
      location,
      about,
    };

    setSavedValues(newValues);
    setIsPopupVisible(true);
  };


  return (
    <Screen style={{
      backgroundColor: schema.backgroundSecondary,
    }} preset="scroll">

      {!isMobile ? (
        <View>

          <View style={{ width: '100%' }}>

            <View style={{ width: "100%", position: "relative" }}>
              <Image source={require("../../assets/images/ProfileBg.png")} style={{ width: '100%', height: 400, resizeMode: 'cover' }} />
            </View>

            <View style={{ width: "20%", position: 'absolute', right: 0, bottom: 10, }}>
              <TouchableOpacity>
                <Card containerStyle={{ width: "70%", backgroundColor: '#35354B', padding: 10, border: 'none', borderRadius: 10 }}>
                  <View style={{ flexDirection: 'row', justifyContent: "space-between", alignItems: 'center', width: "100%" }} >
                    <Image source={require("../../assets/images/camera.png")} style={{ width: 30, height: 30 }} />
                    <Text style={{ color: 'white', fontSize: 18, }}>
                      Edit Cover Photo
                    </Text>
                  </View>
                </Card>
              </TouchableOpacity>

            </View>
          </View>
          <Card containerStyle={{ backgroundColor: '#070C29', border: 'none', borderRadius: 20 }}>

            <View style={{ width: "100%", marginTop: 10, }}>
              <View style={{ width: "100%", flexDirection: 'row', justifyContent: "space-between", alignItems: "center" }}>
                <View style={{ width: 120, }}>
                  <Image source={require("../../assets/images/User.jpg")} style={{ width: 120, height: 120, borderRadius: 10, borderColor: 'white', borderWidth: 2 }} />
                  <View style={{ backgroundColor: "#35354B", width: 40, height: 40, alignItems: "center", justifyContent: 'center', borderRadius: 50, position: 'absolute', bottom: -13, right: 0 }}>
                    <Image source={require('../../assets/images/camera.png')} style={{ width: 20, height: 20, }} />
                  </View>
                </View>
                <View>
                  <TouchableOpacity style={{ backgroundColor: '#35354B', padding: 20, borderRadius: 20 }} ><Text style={{ color: "white", fontSize: 20 }}>Save Changes </Text></TouchableOpacity>
                </View>
              </View>
              <View style={{ width: '100%', marginTop: 20, flexDirection: 'row', justifyContent: "space-between" }}>
                <View style={{ width: "32%" }}>
                  <Text style={{ color: "white", fontSize: 18, marginLeft: 10, marginBottom: 4, }}>Name</Text>
                  <TextInput
                    value={text}
                    placeholder="Enter Your Name "
                    style={{ backgroundColor: "#35354B", color: 'white', padding: 20, borderRadius: 10 }}
                  />
                </View>
                <View style={{ width: "32%" }}>
                  <Text style={{ color: "white", fontSize: 18, marginLeft: 10, marginBottom: 4, }}>Email Id</Text>
                  <TextInput
                    value={text}
                    placeholder="Enter Your Email Id "
                    style={{ backgroundColor: "#35354B", color: 'white', padding: 20, borderRadius: 10 }}
                  />
                </View>
                <View style={{ width: "32%" }}>
                  <Text style={{ color: "white", fontSize: 18, marginLeft: 10, marginBottom: 4, }}> </Text>

                  <Picker
                    selectedValue={selectedValue}
                    onValueChange={(itemValue) => setSelectedValue(itemValue)}
                    style={{ backgroundColor: "#35354B", color: 'white', padding: 17, borderRadius: 10 }}
                  >
                    <Picker.Item label="India" value="India" />
                    <Picker.Item label="Usa" value="Usa" />
                    <Picker.Item label="Japan" value="Japan" />
                  </Picker>
                </View>

              </View>

              <View style={{ width: '100%', marginTop: 20, flexDirection: 'row', justifyContent: "space-between" }}>
                <View style={{ width: "100%" }}>
                  <Text style={{ color: "white", fontSize: 18, marginLeft: 10, marginBottom: 4, }}>Location</Text>
                  <TextInput
                    value={text}
                    placeholder="Share Your Location "
                    style={{ backgroundColor: "#35354B", color: 'white', padding: 20, borderRadius: 10 }}
                  />
                </View>


              </View>

              <View style={{ width: '100%', marginTop: 20, flexDirection: 'row', justifyContent: "space-between" }}>
                <View style={{ width: "100%" }}>
                  <Text style={{ color: "white", fontSize: 18, marginLeft: 10, marginBottom: 5, }}>About Me</Text>
                  <TextInput
                    value={text}
                    placeholder=""
                    style={{ backgroundColor: "#35354B", color: 'white', padding: 20, borderRadius: 10, textAlignVertical: 'top', height: 150, }}
                    multiline={true}
                  />
                </View>


              </View>

            </View>
          </Card>
        </View>
      ) : (


        <>
          {/* {isPopupVisible && (
            <Modal
            visible={isPopupVisible}
            animationType="slide"
            transparent={true}
            onRequestClose={() => setIsPopupVisible(false)}
          >
            <View style={styles.popupContainer}>
              <View style={styles.popup}>
                <Text>Name: {savedValues.name}</Text>
                <Text>Email: {savedValues.email}</Text>
                <Text>Country: {savedValues.country}</Text>
                <Text>Location: {savedValues.location}</Text>
                <Text>About: {savedValues.about}</Text>
                <Button title="Close" onPress={() => setIsPopupVisible(false)} />
              </View>
            </View>
          </Modal>
          )} */}


          <View>

            <View style={{ width: '100%' }}>

              <View style={{ width: "100%", position: "relative" }}>
                <Image source={require("../../assets/images/ProfileBg.png")} style={{ width: '100%', height: 400, resizeMode: 'cover' }} />
              </View>

              <View style={{ width: "17%", position: 'absolute', right: 0, bottom: 10, }}>
                <TouchableOpacity>
                  <Card containerStyle={{ width: "70%", backgroundColor: '#35354B', padding: 10, border: 'none', borderRadius: 10 }}>
                    <View style={{ flexDirection: 'row', justifyContent: "space-between", alignItems: 'center', width: "100%" }} >
                      <Image source={require("../../assets/images/camera.png")} style={{ width: 30, height: 30 }} />
                    </View>
                  </Card>
                </TouchableOpacity>

              </View>
            </View>
            <Card containerStyle={{ backgroundColor: '#070C29', border: 'none', borderRadius: 20 }}>

              <View style={{ width: "100%", marginTop: 10, }}>
                <View style={{ width: "100%", flexDirection: 'row', justifyContent: "space-between", alignItems: "center" }}>
                  <View style={{ width: 120, }}>
                    <Image source={require("../../assets/images/User.jpg")} style={{ width: 120, height: 120, borderRadius: 10, borderColor: 'white', borderWidth: 2 }} />
                    <View style={{ backgroundColor: "#35354B", width: 40, height: 40, alignItems: "center", justifyContent: 'center', borderRadius: 50, position: 'absolute', bottom: -13, right: 0 }}>
                      <Image source={require('../../assets/images/camera.png')} style={{ width: 20, height: 20, }} />
                    </View>
                  </View>
                  <View>
                    <TouchableOpacity onPress={handleSavePress}
                      style={{ backgroundColor: '#35354B', padding: 20, borderRadius: 20 }} ><Text style={{ color: "white", fontSize: 20 }}>Save Changes </Text></TouchableOpacity>
                  </View>
                </View>
                <View style={{ width: '100%', marginTop: 20, justifyContent: "space-between" }}>
                  <View style={{ width: "100%" }}>
                    <Text style={{ color: "white", fontSize: 18, marginLeft: 10, marginBottom: 4, }}>Name</Text>
                    <TextInput
                      value={name}
                      onChangeText={setName}
                      placeholder="Enter Your Name "
                      style={{ backgroundColor: "#35354B", color: 'white', padding: 20, borderRadius: 10 }}
                    />
                  </View>
                  <View style={{ width: "100%", marginTop: 20, }}>
                    <Text style={{ color: "white", fontSize: 18, marginLeft: 10, marginBottom: 4, }}>Email Id</Text>
                    <TextInput
                      value={email}
                      onChangeText={setEmail}
                      placeholder="Enter Your Email Id "
                      style={{ backgroundColor: "#35354B", color: 'white', padding: 20, borderRadius: 10 }}
                    />
                  </View>
                  <View style={{ width: "100%", marginTop: 20, }}>
                    <Text style={{ color: "white", fontSize: 18, marginLeft: 10, marginBottom: 4, }}>Country</Text>

                    <Picker
                      selectedValue={selectedValue}
                      onValueChange={(itemValue) => setSelectedValue(itemValue)}
                      style={{ backgroundColor: "#35354B", color: 'white', padding: 20, borderRadius: 10 }}
                    >
                      <Picker.Item label="India" value="India" />
                      <Picker.Item label="Usa" value="Usa" />
                      <Picker.Item label="Japan" value="Japan" />
                    </Picker>
                  </View>

                </View>

                <View style={{ width: '100%', marginTop: 20, flexDirection: 'row', justifyContent: "space-between" }}>
                  <View style={{ width: "100%" }}>
                    <Text style={{ color: "white", fontSize: 18, marginLeft: 10, marginBottom: 4, }}>Location</Text>
                    <TextInput
                     value={about}
                     onChangeText={setAbout}
                      placeholder="Share Your Location "
                      style={{ backgroundColor: "#35354B", color: 'white', padding: 20, borderRadius: 10 }}
                    />
                  </View>


                </View>

                <View style={{ width: '100%', marginTop: 20, flexDirection: 'row', justifyContent: "space-between" }}>
                  <View style={{ width: "100%" }}>
                    <Text style={{ color: "white", fontSize: 18, marginLeft: 10, marginBottom: 5, }}>About Me</Text>
                    <TextInput
                      value={text}
                      placeholder=""
                      style={{ backgroundColor: "#35354B", color: 'white', padding: 20, borderRadius: 10, textAlignVertical: 'top', height: 150, }}
                      multiline={true}
                    />
                  </View>


                </View>

              </View>
            </Card>
          </View>
        </>

      )
      }

    </Screen >

  )
})
const styles = StyleSheet.create({
  // ... (other styles)
  popupContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  popup: {
    backgroundColor: "white",
    padding: 20,
    borderRadius: 10,
  },
});