
import * as React from "react"
import { StyleProp, TextStyle, View, Text, ViewStyle, StyleSheet, Image, TouchableOpacity } from "react-native"
import { observer } from "mobx-react-lite"
import { colors, typography } from "../theme"
import { Card } from "@rneui/themed"
import { useWindowDimensions } from "react-native"
import { useNavigation } from "@react-navigation/native"
import { isMobile } from "react-device-detect"
export interface QuizGameProps {
    style?: StyleProp<ViewStyle>,
    gameName: string;
    gameSrc: any;
    background: string;
    title: string;
    onGameClick: string;
    Url: string;
    gameplay: string
}
export const QuizGame =
    observer(function QuizGame(props: QuizGameProps) {
        const { style, gameName, gameSrc, Url, onGameClick, title, background, gameplay } = props
        const styles = useStyles()
        const navigation = useNavigation();

        return (

            <View >
                {/* <TouchableOpacity onPress={() => navigation.navigate(onGameClick, { Url, QuizGameName })}> */}

                {!isMobile ? (
                    <View>
                        <Card containerStyle={[styles.QuizGame, { backgroundColor: background }]}>
                            <View style={styles.QuizGameData}>
                                <Image source={gameSrc} style={styles.QuizGameImages} />
                                <View style={styles.QuizGameNameAndDetails}>
                                    <Text style={styles.QuizGameName}>{gameName}</Text>
                                    <Text style={{ color: 'white', paddingLeft: 10 }}>Details</Text>
                                </View>
                                <View>
                                    <TouchableOpacity style={styles.QuizGameStartButton} onPress={() => navigation.navigate(onGameClick, { Url, gameName, gameSrc, gameplay })}>
                                        <Text style={styles.QuizGameStartButtonText}>Start</Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                        </Card>
                    </View>
                ) : (
                    <View style={{ width: "100%" }}>
                        <Card containerStyle={[styles.QuizGame, { backgroundColor: background, padding: 10, }]}>
                            <View style={styles.QuizGameData}>
                                <View style={{ width: "25%" }}>
                                    <Image source={gameSrc} style={styles.QuizGameImages} />
                                </View>
                                <View style={{ width: "50%" }}>
                                    <Text style={[styles.QuizGameName, { width: "100%" }]}>{gameName}</Text>
                                    <Text style={{ color: 'white', paddingLeft: 10 }}>Details</Text>
                                </View>
                                <View style={{ width: "25%" }}>
                                    <TouchableOpacity style={[styles.QuizGameStartButton, { width: "100%" }]} onPress={() => navigation.navigate(onGameClick, { Url, gameName, gameSrc, gameplay })}>
                                        <Text style={styles.QuizGameStartButtonText}>Start</Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                        </Card>
                    </View>
                )}
                {/* </TouchableOpacity> */}
            </View>

        )
    })
const $container: ViewStyle = {
    justifyContent: "center",
}
const $text: TextStyle = {
    fontFamily: typography.primary.normal,
    fontSize: 14, color: colors.palette.primary500,
}

function useStyles() {
    const { width, height } = useWindowDimensions()

    return StyleSheet.create({
        QuizGame: {
            flex: 1,
            margin: 0,
            padding: 10,
            marginTop: 10,
            borderRadius: 20,
            border: "none",
        },
        QuizGameData: {
            flexDirection: "row",
        },
        QuizGameImages: {
            width: (width > 900) ? 100 : 70,
            height: (width > 900) ? 100 : 70,
            borderRadius: 10,

        },
        QuizGameNameAndDetails: {
            flex: 1,
            paddingLeft: 10
        },
        QuizGameName: {
            fontSize: 16,
            paddingLeft: 10,
            marginBottom: 2,
            color: 'white'
        },
        QuizGameStartButton: {
            alignItems: "flex-end",
            fontSize: 20,
            width: 100,
        },
        QuizGameStartButtonText: {
            fontSize: 15,
            marginTop: 10,
            width: "100%",
            color: "white",
            padding: 10,
            marginRight: 10,
            textAlign: "center",
            alignSelf: "center",
            justifyContent: "center",
            backgroundColor: "#35C0E3",
            borderRadius: 100,
        },
    })
}