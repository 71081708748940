import React, { createContext, useContext, useState } from "react";

const ThemeContext = createContext({
  themeColor: "dark", // Default theme color set to "dark"
  setThemeColor: () => {},
});

export const ThemeProvider = ({ children }) => {
  const [themeColor, setThemeColor] = useState("dark"); // Initialize with "dark"

  return (
    <ThemeContext.Provider value={{ themeColor, setThemeColor }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  return useContext(ThemeContext);
};

// import React, { createContext, useContext, useState, useEffect } from "react";
// import AsyncStorage from "@react-native-async-storage/async-storage";

// const ThemeContext = createContext({
//   themeColor: "dark",
//   setThemeColor: () => {},
// });

// export const ThemeProvider = ({ children }) => {
//   const [themeColor, setThemeColorState] = useState("dark");

//   useEffect(() => {
//     AsyncStorage.getItem("themeColor").then((storedThemeColor) => {
//       if (storedThemeColor !== null) {
//         setThemeColorState(storedThemeColor);
//       }
//     });
//   }, []);

//   const setThemeColor = async (color) => {
//     await AsyncStorage.setItem("themeColor", color);
//     setThemeColorState(color);
//   };

//   return (
//     <ThemeContext.Provider value={{ themeColor, setThemeColor }}>
//       {children}
//     </ThemeContext.Provider>
//   );
// };

// export const useTheme = () => {
//   return useContext(ThemeContext);
// };


