import React, { FC, useState } from "react"
import { observer } from "mobx-react-lite"
import { ViewStyle, useColorScheme, View, Dimensions, TouchableOpacity, ImageBackground, Image, Button, Modal, Alert, Picker, ScrollView } from "react-native"
import { StackScreenProps } from "@react-navigation/stack"
import { AppStackScreenProps } from "../navigators"
import { Screen, Text } from "../components"
import theme from "../theme/theme"
import { TextInput } from "react-native-gesture-handler"
import { useNavigation } from "@react-navigation/native"
import { useHeaderHeight } from '@react-navigation/elements';
import { isMobile } from "react-device-detect";
import { Card } from "@rneui/base"
import { useTheme } from "../constant/TheamContext"
// import OTPInputView from '@twotalltotems/react-native-otp-input';

// import { useNavigation } from "@react-navigation/native"
// import { useStores } from "../models"

// STOP! READ ME FIRST!
// To fix the TS error below, you'll need to add the following things in your navigation config:
// - Add `UserRegistration: undefined` to AppStackParamList
// - Import your screen, and add it to the stack:
//     `<Stack.Screen name="UserRegistration" component={UserRegistrationScreen} />`
// Hint: Look for the 🔥!

// REMOVE ME! ⬇️ This TS ignore will not be necessary after you've added the correct navigator param type
// @ts-ignore
export const UserRegistrationScreen: FC<StackScreenProps<AppStackScreenProps, "UserRegistration">> = observer(function UserRegistrationScreen() {
  // Pull in one of our MST stores
  // const { someStore, anotherStore } = useStores()
  const { themeColor } = useTheme();
      
  const schema = themeColor === "dark" ? theme.dark : theme.light;
  
  const windowWidth = Dimensions.get('window').width;
  const { height } = Dimensions.get('window');
  const headerHeight = useHeaderHeight() + 0;
  const [text, setText] = useState('');

  const [showModal, setShowModal] = useState(false);
  const navigation = useNavigation()

  const [otp, setOTP] = useState('');
  const [isOTPFilled, setIsOTPFilled] = useState(false);

  const handleOTPChange = (input) => {
    // Remove all non-numeric characters
    const numericInput = input.replace(/\D/g, '');
    // Limit the input to 6 characters (assuming 6-digit OTP)
    const truncatedInput = numericInput.slice(0, 6);
    setOTP(truncatedInput);

    setIsOTPFilled(truncatedInput.length === 6);

  };
  const [mobileNumber, setMobileNumber] = useState('');

  const handleMobileNumberChange = (input) => {
    // Remove all non-numeric characters
    const numericInput = input.replace(/\D/g, '');
    // Limit the input to 10 characters
    const truncatedInput = numericInput.slice(0, 10);
    setMobileNumber(truncatedInput);
  };

  const [selectedValue, setSelectedValue] = useState('');

  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const [OtpError, setOTPError] = useState('');

  const handleVerify = () => {
    if (isOTPFilled) {
      navigation.navigate("HomeScreen");
    } else {
      setOTPError('Please enter a valid OTP');
    }
  };

  const [isPopoverOTPVisible, setIsPopoverOTPVisible] = useState(false);
  
  const [mobileNumberError, setMobileNumberError] = useState('');

  const handleOTPInputs = () => {
    if (mobileNumber.length === 10) { // Check if a valid mobile number is entered
      setIsPopoverOTPVisible(true);
    } else {

      setMobileNumberError('Please enter a valid 10-digit mobile number');
    }
  };
  const hidePopover = () => {
    setIsPopoverOTPVisible(false);
  };

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
      alwaysBounceHorizontal={false}
      alwaysBounceVertical={false}
      style={{ backgroundColor: schema.backgroundSecondary }}
    >
      {!isMobile ? (
        <View style={{ flexDirection: "row" }}>
          <View style={{ width: windowWidth * 0.3, backgroundColor: "#24172E", position: "relative" }}>
              <View style={{ backgroundColor: 'white', width: "100%", marginTop: 90, height: height - headerHeight - 100, borderTopLeftRadius: 50, borderTopRightRadius: 50, padding: 20, }}>

                <View style={{ alignItems: "center", marginTop: 50 }}>
                  <Text style={{ fontSize: 30, fontFamily: "poppins", fontWeight: "900" }}>Login</Text>
                </View>
                
              <View style={{ marginTop: 40, alignItems: "center" }}>
                {mobileNumberError !== '' && (
                  <Text style={{ color: 'red', marginTop: 5 }}>{mobileNumberError}</Text>
                )}

              </View>
              <View style={{ flexDirection: "row", width: "100%", marginTop: 10, }}>

                <Picker
                  selectedValue={selectedValue}
                  style={{ height: 40, width: 100, marginLeft: 20, borderRadius: 10, border: "2px solid #d5d7e3" }}
                  onValueChange={(itemValue: React.SetStateAction<string>) => setSelectedValue(itemValue)}
                >
                  <Picker.Item label="+91" value="option1" />
                  <Picker.Item label="+1" value="option2" />
                  <Picker.Item label="+299" value="option3" />
                </Picker>

                <TextInput
                  placeholder="Enter Your Mobile Number"
                  value={mobileNumber}
                  onChangeText={handleMobileNumberChange}
                  style={{
                    width: 500,
                    marginLeft: 20,
                    borderRadius: 10,
                    padding: 10,
                    borderWidth: 2,
                    borderColor: '#d5d7e3',
                  }}
                  keyboardType="numeric"
                />
              </View>

              <View style={{ width: "100%", alignItems: "center", marginTop: 30, }}>
                <TouchableOpacity onPress={handleOTPInputs} style={{ width: "90%", backgroundColor: "#070B28", borderRadius: 10, padding: 10, flexDirection: "row", justifyContent: "space-between", }}>
                  <Text style={{ color: "white", fontFamily: "poppins", fontSize: 18, paddingLeft: 10, }}>
                    Login
                  </Text>
                  <Image source={require("../../assets/images/loginrightarrow.png")} style={{ width: 20, height: 10, marginTop: 8, paddingRight: 10, }} />
                </TouchableOpacity>

                {isPopoverOTPVisible && (

                  <Modal animationType="none" transparent={true} visible={isPopoverOTPVisible}>
                    <View style={{ flex: 1,width:windowWidth * 0.3 }}>
                      
                      <View style={{    height: "100%", backgroundColor: "white", borderTopLeftRadius: 10, borderTopRightRadius: 10, padding: 10, }}>
                        
                        <View style={{ width:"100%", alignItems:'center', justifyContent:'center', marginTop:50,}}>
                          <Image source={require('../../assets/images/OTPVarify.png')} style={{width:100, height:100}} />
                          <Text style={{fontSize:20, fontWeight:'900', fontFamily:'', marginTop:20}}>Verification Code </Text>
                          <Text style={{alignItems:'center', justifyContent:'center', textAlign:'center', width:"80%"}}>
                            Please type the Verification code sent to {mobileNumber}
                          </Text>
                        </View>
                        
                        <View style={{ marginTop: 40, alignItems: "center" }}>
                          {OtpError !== '' && (
                            <Text style={{ color: 'red', marginTop: 5 }}>{OtpError}</Text>
                          )}

                        </View>
                        
                        <TextInput
                          placeholder="Enter OTP"
                          value={otp}
                          onChangeText={handleOTPChange}
                          style={{
                            width: '100%',
                            marginTop: 10,
                            padding: 10,
                            borderWidth: 1,
                            borderColor: '#d5d7e3',
                            borderRadius: 5,
                            alignItems: 'center',
                            textAlign: 'center'
                          }}
                          autoComplete="one-time-code"
                          keyboardType="numeric"
                        />


                        <View style={{ marginTop: 20 }}>
                          <TouchableOpacity onPress={handleVerify} style={{ backgroundColor: isOTPFilled ? 'orange' : '#ECECEC', alignItems: 'center', justifyContent: 'center', padding: 10, }}>
                            <Text style={{ fontFamily: '' }}>Verify</Text>
                          </TouchableOpacity>

                          <TouchableOpacity onPress={hidePopover} style={{ backgroundColor: "#EAFAFF", alignItems: 'center', justifyContent: 'center', padding: 10, marginTop:10 }}>
                            <Text style={{ fontFamily: '' }}>Resend Otp</Text>
                          </TouchableOpacity>
                        </View>

                      </View>


                    </View>
                  </Modal>
                )}

                <Text style={{ marginTop: 10, fontFamily: "poppins" }}>OR</Text>
              </View>

              <View style={{ width: "100%", alignItems: "center", marginTop: 10 }}>
                <TouchableOpacity
                  onPress={() => navigation.navigate("user")}
                  style={{
                    width: "90%", padding: 8, flexDirection: "row", shadowColor: "black",
                    shadowOffset: { width: 0, height: 2 },
                    shadowOpacity: 0.2,
                    shadowRadius: 10,
                    borderRadius: 10
                  }}>

                  <Image source={require("../../assets/images/google.png")} style={{ width: 20, height: 20, resizeMode: "contain" }} />
                  <Text style={{ fontFamily: "poppins", marginLeft: 80 }}>
                    Sign in with Google
                  </Text>
                </TouchableOpacity>
              </View>
               
                <View style={{ justifyContent: "center", alignItems: "center" }}>
                  <Text style={{ width: 250, textAlign: "center", fontFamily: 'Poppins', marginTop: 20, }}>
                    By Login , I agree to P9Games <b>Terms and Conditions</b> and <b>Privacy Policy</b>
                  </Text>
                </View>
              </View>


            <View style={{ width: "100%", position: "absolute", top: 20, }}>
              <Image source={require("../../assets/images/loginLogo.png")} style={{ width: "100%", height: 100, resizeMode: "contain", }} />
            </View>
          </View>

          <Image source={require("../../assets/images/login.jpg")} style={{ width: "100%", height: height, opacity: .5 }} />
        </View>
      ) : (
        <>
          <View style={{ width: windowWidth, backgroundColor: "#24172E", position: "relative" }}>
            <View style={{ backgroundColor: 'white', width: "100%", marginTop: 300, height: height - headerHeight - 300, borderTopLeftRadius: 50, borderTopRightRadius: 50, padding: 20, }}>

              <View style={{ alignItems: "center", marginTop: 50 }}>
                <Text style={{ fontSize: 30, fontFamily: "poppins", fontWeight: "900" }}>Login</Text>
              </View>
              <View style={{ marginTop: 40, alignItems: "center" }}>
                {mobileNumberError !== '' && (
                  <Text style={{ color: 'red', marginTop: 5 }}>{mobileNumberError}</Text>
                )}

              </View>
              <View style={{ flexDirection: "row", width: "100%", marginTop: 10, }}>

                <Picker
                  selectedValue={selectedValue}
                  style={{ height: 40, width: 100, marginLeft: 20, borderRadius: 10, border: "2px solid #d5d7e3" }}
                  onValueChange={(itemValue: React.SetStateAction<string>) => setSelectedValue(itemValue)}
                >
                  <Picker.Item label="+91" value="option1" />
                  <Picker.Item label="+1" value="option2" />
                  <Picker.Item label="+299" value="option3" />
                </Picker>
                
                <TextInput
                  placeholder="Enter Your Mobile Number"
                  value={mobileNumber}
                  onChangeText={handleMobileNumberChange}
                  style={{
                    width: 500,
                    marginLeft: 20,
                    borderRadius: 10,
                    padding: 10,
                    borderWidth: 2,
                    borderColor: '#d5d7e3',
                  }}
                  keyboardType="numeric"
                />
              </View>

              <View style={{ width: "100%", alignItems: "center", marginTop: 30, }}>
                <TouchableOpacity onPress={handleOTPInputs} style={{ width: "90%", backgroundColor: "#070B28", borderRadius: 10, padding: 10, flexDirection: "row", justifyContent: "space-between", }}>
                  <Text style={{ color: "white", fontFamily: "poppins", fontSize: 18, paddingLeft: 10, }}>
                    Login
                  </Text>
                  <Image source={require("../../assets/images/loginrightarrow.png")} style={{ width: 20, height: 10, marginTop: 8, paddingRight: 10, }} />
                </TouchableOpacity>

                {isPopoverOTPVisible && (

                  <Modal animationType="none" transparent={true} visible={isPopoverOTPVisible}>
                    <View style={{ flex: 1 }}>
                      
                      <View style={{ height: "100%", backgroundColor: "white", borderTopLeftRadius: 10, borderTopRightRadius: 10, padding: 10, }}>
                        
                        <View style={{ width:"100%", alignItems:'center', justifyContent:'center', marginTop:50,}}>
                          <Image source={require('../../assets/images/OTPVarify.png')} style={{width:100, height:100}} />
                          <Text style={{fontSize:20, fontWeight:'900', fontFamily:'', marginTop:20}}>Verification Code </Text>
                          <Text style={{alignItems:'center', justifyContent:'center', textAlign:'center', width:"80%"}}>
                            Please type the Verification code sent to {mobileNumber}
                          </Text>
                        </View>
                        
                        <View style={{ marginTop: 40, alignItems: "center" }}>
                          {OtpError !== '' && (
                            <Text style={{ color: 'red', marginTop: 5 }}>{OtpError}</Text>
                          )}

                        </View>
                        
                        <TextInput
                          placeholder="Enter OTP"
                          value={otp}
                          onChangeText={handleOTPChange}
                          style={{
                            width: '100%',
                            marginTop: 10,
                            padding: 10,
                            borderWidth: 1,
                            borderColor: '#d5d7e3',
                            borderRadius: 5,
                            alignItems: 'center',
                            textAlign: 'center'
                          }}
                          autoComplete="one-time-code"
                          keyboardType="numeric"
                        />


                        <View style={{ marginTop: 20 }}>
                          <TouchableOpacity onPress={handleVerify} style={{ backgroundColor: isOTPFilled ? 'orange' : '#ECECEC', alignItems: 'center', justifyContent: 'center', padding: 10, }}>
                            <Text style={{ fontFamily: '' }}>Verify</Text>
                          </TouchableOpacity>
                          
                          <TouchableOpacity onPress={hidePopover} style={{ backgroundColor: "#EAFAFF", alignItems: 'center', justifyContent: 'center', padding: 10, marginTop:10 }}>
                            <Text style={{ fontFamily: '' }}>Resend Otp</Text>
                          </TouchableOpacity>
                        </View>

                      </View>


                    </View>
                  </Modal>
                )}

                <Text style={{ marginTop: 10, fontFamily: "poppins" }}>OR</Text>
              </View>
              <View style={{ width: "100%", alignItems: "center", marginTop: 10 }}>
                <TouchableOpacity
                  onPress={() => navigation.navigate("user")}
                  style={{
                    width: "90%", padding: 8, flexDirection: "row", shadowColor: "black",
                    shadowOffset: { width: 0, height: 2 },
                    shadowOpacity: 0.2,
                    shadowRadius: 10,
                    borderRadius: 10
                  }}>

                  <Image source={require("../../assets/images/google.png")} style={{ width: 20, height: 20, resizeMode: "contain" }} />
                  <Text style={{ fontFamily: "poppins", marginLeft: 80 }}>
                    Sign in with Google
                  </Text>
                </TouchableOpacity>
              </View>
            </View>


            <View style={{ width: "100%", position: "absolute", top: 230, }}>
              <Image source={require("../../assets/images/loginLogo.png")} style={{ width: "100%", height: 100, resizeMode: "contain", }} />
            </View>
          </View>
        </>

      )}
    </ScrollView>


  )
})
