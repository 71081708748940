import React, { FC, useContext, useRef, useState } from "react"
import { StyleProp, TextStyle, View, ViewStyle, StyleSheet, Image, Text, TouchableOpacity } from "react-native"
import { observer } from "mobx-react-lite"
import { Card } from "@rneui/themed"
import { useWindowDimensions } from "react-native"
import CountDownTimer from 'react-native-countdown-timer-hooks';
// import CountDown from 'react-native-countdown-component';
export interface ActivityScreenProps {
    /**
     * An optional style override useful for padding & margin.
     */
    style?: StyleProp<ViewStyle>, PrizePool, source, Players, BackGround, ActivityTime
}

/**
 * Describe your component here
 */
export const ActivityScreen = observer(function SissionScreenGames(props: ActivityScreenProps) {
    const { style, PrizePool, source, Players, BackGround, ActivityTime } = props
    const styles = useStyles()
    const refTimer = useRef();

    // For keeping a track on the Timer
    const [timerEnd, setTimerEnd] = useState(false);

    const timerCallbackFunc = (timerFlag) => {
        // Setting timer flag to finished
        setTimerEnd(timerFlag);
        console.warn(
            'You can alert the user by letting him know that Timer is out.',
        );
    };

    return (
        <View >
            <View style={styles.SessionsScreenMainContainer}>
                <Card containerStyle={[styles.SessionSceenGamesCard, { backgroundColor: BackGround, }]}>
                    <View style={styles.SessionSceenGamesImages}>
                        <Image source={source} style={{ width: 100, height: 100, borderRadius: 20 }}></Image>
                    </View>
                    <View style={[styles.SessionSceenGamePrizePoolText]}>
                        <View style={{ alignItems: "center" }}>
                            <Text style={styles.SessionSceenGamePrizeText}>Prize Pool</Text>
                            <Text style={styles.SessionSceenGamePrizePoolAmount}>${PrizePool}</Text>
                        </View>
                        <View style={styles.SessionSceenGameActiveUser}>
                            <Text style={styles.SessionSceenGameActiveUserText}>Players</Text>
                            <Text style={styles.SessionSceenGameActiveUserCount}>{Players}</Text>
                        </View>
                        <View style={styles.SessionSceenGameActiveUser}>
                            <Text style={styles.SessionSceenGameActiveUserText}>Time Left</Text>
                            <Text style={[styles.SessionSceenGameActiveUserCount, {}]} onPress={() => {
                                setTimerEnd(false);
                                // refTimer.current.resetTimer();
                            }}><CountDownTimer
                                    ref={refTimer}
                                    timestamp={ActivityTime}
                                    timerCallback={timerCallbackFunc}

                                    textStyle={{
                                        fontSize: 15,
                                        color: '#FFFFFF',
                                        fontWeight: '800',
                                    }}
                                />
                                <Text style={{ marginTop: -18, fontSize: 15, color: '#FFFFFF', fontWeight: 'bold', display: timerEnd ? 'flex' : 'none', }}>
                                    Session End
                                </Text>
                            </Text>
                        </View>
                        {/* <View
                            style={{
                                flex: 1,
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                            <View style={{ display: timerEnd ? 'none' : 'flex' }}>

                            </View>

                        </View> */}

                    </View>
                </Card>
            </View>
        </View >
    )
})
function useStyles() {
    const { width, height } = useWindowDimensions()

    return StyleSheet.create({
        SessionsScreenMainContainer: {
            paddingBottom: 10,
        },
        SessionSceenGamesCard: {

            flexDirection: "row",
            padding: 0,
            marginTop: 20,
            width: (width > 900) ? "90%" : "95%",
            height: 150,
            borderRadius: 10,
        },
        SessionSceenGamesImages: {
            position: "absolute",
            left: -20,
            top: 15,
            borderRadius: 20,
            border: "2px solid white",

        },
        SessionSceenGamePrizePoolText: {
            width: (width > 900) ? "70%" : "10%",
            marginLeft: (width > 900) ? 100 : 88,
            marginTop: 50,
            flexDirection: "row",

        },
        SessionSceenGamePrizeText: {
            fontSize: 16,
            fontWeight: "700",
            color: "white",
        },
        SessionSceenGamePrizePoolAmount: {
            fontSize: 15,
            fontWeight: "800",
            color: "white",
        },
        SessionSceenGameActiveUser: {
            marginLeft: 20,
            alignItems: "center"
        },
        SessionSceenGameActiveUserText: {
            fontSize: 16,
            fontWeight: "700",
            color: "white",
        },
        SessionSceenGameActiveUserCount: {
            fontSize: 15,
            fontWeight: "800",
            color: "white",
        },
    })
}