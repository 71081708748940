import React, { FC, useState, useEffect } from "react";
import { StyleProp, TextStyle, View, ViewStyle, Image, useColorScheme, TouchableOpacity } from "react-native"
import { observer } from "mobx-react-lite"
import { colors, typography } from "../theme"
import { Text } from "./Text"
import { fc, qc, bd, cd } from "../constant/imagePath";
import { useTheme } from "../constant/TheamContext"
import { useNavigation } from "@react-navigation/native";

export interface DisplayCardsProps {
  style?: StyleProp<ViewStyle>
  gameName: string;
  gameSrc: any;
  background: string;
  title: string;
  onGameClick: string;
  Url: string;
  gameplay:string
}

import theme from "../theme/theme"

export const DisplayCards = observer(function DisplayCards(props: DisplayCardsProps) {
  const { style, gameName, gameSrc, background, title, onGameClick, Url, gameplay,} = props;
  const colorScheme = useColorScheme();
  const { themeColor } = useTheme();
  const schema = themeColor === "dark" ? theme.dark : theme.light;

  const navigation = useNavigation();
  return (
    <>
      <View style={{ flexDirection: 'row', marginLeft: 10 }} >
        <View style={{ width: "40%" }}>
          <View style={{ flexDirection: "row" }}>
            <View style={{ borderRadius: 10, overflow: "hidden" }}>
              <TouchableOpacity onPress={() => navigation.navigate(onGameClick, { Url, gameName, gameSrc, gameplay})} >
                <Image source={gameSrc} style={{ width: 200, height: 200 }} />
              </TouchableOpacity>


              
            </View>
          </View>
        </View>
      </View>
    </>
  )
})
