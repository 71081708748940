import React, { useRef, useState } from "react"
import { StyleProp, View, ViewStyle, StyleSheet, Image, Text, TouchableOpacity } from "react-native"
import { observer } from "mobx-react-lite"
import { Card } from "@rneui/themed"
import { useWindowDimensions } from "react-native"
import CountDownTimer from 'react-native-countdown-timer-hooks';
import { isMobile } from "react-device-detect";

export interface SissionScreenGamesProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>, PrizePool, source, Players, BackGround, GameOverTime
}

/**
 * Describe your component here
 */
export const SissionScreenGames = observer(function SissionScreenGames(props: SissionScreenGamesProps) {
  const { style, PrizePool, source, Players, BackGround, GameOverTime } = props
  const styles = useStyles()
  const refTimer = useRef();
  // For keeping a track on the Timer
  const [timerEnd, setTimerEnd] = useState(false);

  const timerCallbackFunc = (timerFlag) => {
    // Setting timer flag to finished
    setTimerEnd(timerFlag);
  };

  return (

    <>
      {!isMobile ? (
        <Card containerStyle={{ backgroundColor: BackGround, alignItems: "center", width: "100%", padding: 10, marginLeft: 0, borderRadius: 10, border:'none' }}>
          <View>
            <Image source={source} style={{ width: 200, height: 200, borderRadius: 10 }} />
          </View>
          <View>
            <View style={{ flexDirection: 'row', justifyContent: "space-between", marginTop: 10, width: '111%', marginLeft: -9, borderBottomColor: 'white', borderBottomWidth: 1, paddingBottom: 3, }}>
              <Text style={{ color: "white", marginLeft: 0 }}>Price Pool</Text><Text style={{ color: "white", marginRight: 10, fontWeight:'700' }}>{PrizePool}</Text>
            </View>

            <View style={{ flexDirection: 'row', justifyContent: "space-between", marginTop: 10, width: '111%', marginLeft: -9, borderBottomColor: 'white', borderBottomWidth: 1, paddingBottom: 3, }}>
              <Text style={{ color: "white", marginLeft: 0 }}>Players</Text><Text style={{ color: "white", marginRight: 10, fontWeight:'700' }}>{Players}</Text>
            </View>

            <View style={{ flexDirection: 'row', justifyContent: "space-between", marginTop: 10, width: '111%', marginLeft: -9, paddingBottom: 3, }}>
              <Text style={{ color: "white", marginLeft: 0 }}>Time Left</Text><Text style={{ color: "white", marginRight: 10, fontWeight:'700' }}>
                <Text style={[styles.SessionSceenGameActiveUserCount, {}]} onPress={() => {
                  setTimerEnd(false);
                }}>
                  <CountDownTimer
                    ref={refTimer}
                    timestamp={GameOverTime}
                    timerCallback={timerCallbackFunc}
                    textStyle={{
                      color: '#FFFFFF',
                    }}
                  />
                  <Text style={{ color: '#FFFFFF', display: timerEnd ? 'flex' : 'none', position: 'absolute', top: 0, width: 100, right: 0 }}>
                    Session End
                  </Text>
                </Text>

              </Text>
            </View>
          </View>
        </Card>
      ) : (
        <>
          <Card containerStyle={{ backgroundColor: BackGround, alignItems: "center", width: "100%",border:'none',  padding: 10, marginLeft: 0, borderRadius: 10 }}>
            <View>
              <Image source={source} style={{ width: 150, height: 150, borderRadius: 10 }} />
            </View>
            <View>
              <View style={{ flexDirection: 'row', justifyContent: "space-between", marginTop: 10, width: '111%', marginLeft: -9, borderBottomColor: 'white', borderBottomWidth: 1, paddingBottom: 3, }}>
                <Text style={{ color: "white", marginLeft: 0 }}>Price Pool</Text><Text style={{ color: "white", marginRight: 10, fontWeight:"600" }}>{PrizePool}</Text>
              </View>

              <View style={{ flexDirection: 'row', justifyContent: "space-between", marginTop: 10, width: '111%', marginLeft: -9, borderBottomColor: 'white', borderBottomWidth: 1, paddingBottom: 3, }}>
                <Text style={{ color: "white", marginLeft: 0 }}>Players</Text><Text style={{ color: "white", marginRight: 10, fontWeight:"600"  }}>{Players}</Text>
              </View>

              <View style={{ flexDirection: 'row', justifyContent: "space-between", marginTop: 10, width: '111%', marginLeft: -9, paddingBottom: 3, }}>
                <Text style={{ color: "white", marginLeft: 0 }}>Time Left</Text><Text style={{ color: "white", marginRight: 10 }}>
                  <Text style={[styles.SessionSceenGameActiveUserCount, {}]} onPress={() => {
                    setTimerEnd(false);
                  }}>
                    <CountDownTimer
                      ref={refTimer}
                      timestamp={GameOverTime}
                      timerCallback={timerCallbackFunc}
                      textStyle={{
                        color: '#FFFFFF',
                        fontWeight:"600" 
                      }}
                    />
                    <Text style={{ color: '#FFFFFF', display: timerEnd ? 'flex' : 'none', position: 'absolute', top: 0, width: 100, right: -10, fontWeight:"600"  }}>
                      Session End
                    </Text>
                  </Text>

                </Text>
              </View>
            </View>
          </Card>
        </>
      )}
    </>
  )
})

function useStyles() {
  const { width, height } = useWindowDimensions()

  return StyleSheet.create({
    SessionsScreenMainContainer: {
      flexDirection: "column",
      padding: 0,
      textAlign: "flex-start",
      width: width,
      borderRadius: 20,
      margin: "auto",
    },
    SessionSceenGamesCard: {
      flexDirection: "column",
      flex: 1,
      textAlign: "flex-start",
      height: 80,
      width: width - 40,
      borderRadius: 20,
    },
    SessionSceenGamesImages: {
      position: "absolute",
      left: -30,
      top: -5,
      borderRadius: 20,
      border: "1px solid white",
    },
    SessionSceenGamePrizePoolText: {
      flexDirection: "row",
      justifyContent: "space-around",
    },
    SessionSceenGamePrizeText: {
      fontSize: 12,
      fontWeight: "600",
      color: "white",
    },
    SessionSceenGamePrizePoolAmount: {
      fontSize: 14,
      fontWeight: "800",
      color: "white",
    },
    SessionSceenGameActiveUser: {
      alignItems: "center",
      marignRight: 40,
    },
    SessionSceenGameActiveUserText: {
      fontSize: 12,
      fontWeight: "600",
      color: "white",
    },
    SessionSceenGameActiveUserCount: {
      color: "white",
    },

  })
}
