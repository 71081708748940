import React, { FC, useState, useEffect } from "react";
import { StyleProp, TextStyle, View, ViewStyle, Image, useColorScheme, TouchableOpacity } from "react-native"
import { observer } from "mobx-react-lite"
import { colors, typography } from "../theme"
import { Text } from "./Text"
import { fc, qc, bd, cd } from "../constant/imagePath";
import { useTheme } from "../constant/TheamContext"
import { useNavigation } from "@react-navigation/native";

export interface DisplayCardsSecoundProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
  gameName: string;
  gameSrc: any;
  background: string;
  title: string;
  onGameClick: string;
  Url: string;
  gameplay:string

}

/**
 * Describe your component here
 */
export const DisplayCardsSecound = observer(function DisplayCardsSecound(props: DisplayCardsSecoundProps) {
  const { style, gameName, gameSrc, background, title, onGameClick, Url, gameplay, } = props
  
  const navigation = useNavigation();
  return (
    <>
    <TouchableOpacity onPress={() => navigation.navigate(onGameClick, { Url, gameName, gameSrc, gameplay})}>

    <View style={{marginLeft:20, marginTop:10, borderRadius:10, overflow:"hidden"}}>
      <Image source={gameSrc} style={{ width: 90, height: 90 }} >
      </Image>
    </View>
    </TouchableOpacity>
    
    </>
  )
})

const $container: ViewStyle = {
  justifyContent: "center",
}

const $text: TextStyle = {
  fontFamily: typography.primary.normal,
  fontSize: 14,
  color: colors.palette.primary500,
}
